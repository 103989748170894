import React, { useState, useMemo, useEffect } from "react";
import Api from "../../Api";
import Header from "./Header";
import Pannel from "./Pannel";
import { getLangFromLocale, getUnitFromLocale, loc, parsePhoneNumber } from "../../helpers";
import { NavAnalytics } from "./NavAnalytics";
import { NavInfos } from "./NavInfos";
import { Divider } from "./Icons";
import Dropdown from "react-dropdown";
import { useToast } from "../../contexts/ToastContext";

import mixpanel from "mixpanel-browser";

function Teams({ teams, lang }) {
  const teamList = teams.map((team) => (
    <div key={team.id}>
      <div className="mt-3">
        <li>{team.name}</li>
      </div>
      <div className="w-full border-b border-grey-200 h-4"></div>
    </div>
  ));
  return teams.length > 0 ? (
  <ul className="overflow-auto h-96">
    {teamList}
  </ul>
  ) : (
    <p>{loc("Aucune équipe assignée", lang)}</p>
  );
}

function Sites({ sites, lang }) {
  const siteList = sites.map((site) => (
    <div key={site.id}>
      <div className="mt-3">
        <li>{site.name}</li>
      </div>
      <div className="w-full border-b border-grey-200 h-4"></div>
    </div>
  ));
  return <ul className="overflow-auto h-96">{siteList}</ul>;
}

function Infos({ moba, setLocale, locale, lang }) {
  const [first_name, setFirstName] = useState(
    moba.details.user.firstname
  );

  const [last_name, setLastName] = useState(moba.details.user.lastname);
  const [email, setEmail] = useState(moba.details.user.email);
  const [sites, setSites] = useState(moba.details.user.sites);
  const [teams, setTeams] = useState(moba.details.user.teams);
  const [role, setRole] = useState(moba.details.user.role);
  const [number, setNumber] = useState(moba.details.user.phone);
  const [localeLocal, setLocaleLocal] = useState(locale);
  const [currentPage, setCurrentPage] = useState("profile");
  const [disabled, setDisabled] = useState(false);

  const { showToast } = useToast();

  const handleChange = (e) => {
    setDisabled(false);
    if (e.target.name === "first_name") {
      setFirstName(e.target.value);
    } else if (e.target.name === "last_name") {
      setLastName(e.target.value);
    } else if (e.target.name === "email") {
      setEmail(e.target.value);
    } else if (e.target.name === "number") {
      var phone = parsePhoneNumber(e.target.value);
      setNumber(phone);
    } else if (e.target.name === "role") {
      setRole(e.target.value);
    } else if (e.target.name === "sites") {
      setSites(e.target.value);
    } else if (e.target.name === "teams") {
      setTeams(e.target.value);
    }
  };

  const handleChangeLocale = (lang) => {
    setDisabled(false);
    setLocaleLocal(lang.value);
  };

  const saveInfos = () => {
    new Api().fetch(
      "mobaEditPersonalInfos",
      {
        firstname: first_name,
        lastname: last_name,
        email: email,
        locale: localeLocal,
        phone: number,
      },
      (result) => {
        if (result.err) {
          showToast(
            loc("Erreur", lang),
            loc(result.msg, lang),
            "error"
          );
          return;
        } else {
          setLocale(localeLocal);

          const lang = getLangFromLocale(localeLocal);
          
          Api.lang = lang;

          showToast(
            loc("Données enregistrées", lang),
            loc("Les données ont été enregistrées avec succès", lang),
            "success"
          );
          moba.setName(first_name, last_name);
          setDisabled(true);
        }
      }
    );
  };

  const teamsToDisplay = useMemo(() => {
    return teams.filter((team) => {
      return team.name !== "Site Admin" && team.name !== "Admin";
    });
  });

  useEffect(() => {
    moba.fetchAvailableLocales();
    mixpanel.track("User Opened Page", { page: "Profile" });
  }, []);

  const saasAvailableLocales = useMemo(() => {
    if (!moba.available_locales) return [];
    const supportedLocalesCode = ["fr_FR", "en_WW", "en_UK"]; 
    return moba.available_locales.filter((locale) => {
      return supportedLocalesCode.includes(locale.code);
    })
  }, [moba.available_locales]);

  return (
    <div className="flex h-full min-h-screen overflow-y-hidden">
      <Pannel type="infos" lang={lang} />
      <div className="flex flex-col flex-grow">
        <Header connected={true} moba={moba} lang={lang} />
        <div className="flex flex-col mt-10 mx-16">
          <h1 className="font-aeonik text-2xl text-grey-800">
            {loc("Mes informations personnelles", lang)}
          </h1>
          <div className="flex mt-4">
            <NavInfos
              lang={lang}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
          {currentPage === "profile" && (
            <form className="grid grid-cols-2">
              <div className="flex flex-col">
                <label className="text-sm ml-0 mr-auto mt-4 mb-2 font-aeonik text-gray-600 font-medium">
                  {loc("Prénom", lang)}
                </label>
                <input
                  onChange={handleChange}
                  className="bg-white h-10 rounded-md border w-10/12 p-2"
                  name="first_name"
                  value={first_name}
                />
              </div>
              <div className="flex flex-col">
                <label className="text-sm ml-0 mr-auto mt-4 mb-2 font-aeonik text-gray-600 font-medium">
                  {loc("Nom", lang)}
                </label>
                <input
                  onChange={handleChange}
                  className="bg-white h-10 rounded-md border w-10/12 p-2"
                  name="last_name"
                  required
                  value={last_name}
                />
              </div>
              <div className="flex flex-col">
                <label className="text-sm ml-0 mr-auto mt-4 mb-2 font-aeonik text-gray-600 font-medium">
                  {loc("Adresse e-mail", lang)}
                </label>
                <input
                  onChange={handleChange}
                  className="bg-white h-10 rounded-md border w-10/12 p-2"
                  name="email"
                  required
                  value={email}
                />
              </div>
              <div className="flex flex-col">
                <label className="text-sm ml-0 mr-auto mt-4 mb-2 font-aeonik text-gray-600 font-medium">
                  {loc("Numéro de téléphone", lang)}
                </label>
                <input
                  onChange={handleChange}
                  className="bg-white h-10 rounded-md border w-10/12 p-2"
                  name="number"
                  required
                  value={number}
                />
              </div>
              <div className="flex items-center mt-10">
                <h1 className="font-aeonik text-grey-700">
                  {loc("Rôle", lang)}
                </h1>
                <p className="font-aeonik text-grey-500 ml-3">
                  {loc(role, lang)}
                </p>
              </div>
              <div></div>
              <div className="flex flex-col bg-white border rounded-md w-10/12 p-3 mt-10" style={{minHeight:'16rem'}}>
                <h1 className="text-gray-800 text-xl font-aeonik">
                  {loc("Sites", lang)}
                </h1>
                {
                  sites.length > 0 ? (
                    <div className="mt-3 text-grey-500 font-aeonik w-100 h-100">
                      <Sites sites={sites} />
                    </div>
                  ) : (
                    <div className="mt-3 text-grey-500 font-aeonik w-100 h-100 flex justify-center items-center">
                      <p>{loc("Aucun site assigné", lang)}</p>
                    </div>
                  )
                }
              </div>
              <div className="flex flex-col bg-white border rounded-md w-10/12 p-3 mt-10" style={{minHeight:'16rem'}}>
                <h1 className="text-gray-800 text-xl font-aeonik">
                  {loc("Équipes", lang)}
                </h1>
                {
                  teamsToDisplay.length > 0 ? (
                    <div className="mt-3 text-grey-500 font-aeonik w-100 h-100">
                      <Teams
                        teams={teamsToDisplay}
                      />
                    </div>
                  ) : (
                    <div className="mt-3 text-grey-500 font-aeonik w-100 h-100 flex justify-center items-center">
                      <p>{loc("Aucune équipe assignée", lang)}</p>
                    </div>
                  )
                }
              </div>
            </form>
          )}
          {currentPage !== "profile" && (
            <div className="flex flex-col">
              <div className="flex flex-col mt-4 w-80">
                <label className="text-sm ml-0 mr-auto mt-4 mb-2 font-aeonik text-gray-600 font-medium">
                  {loc("Locale", lang)}
                </label>
                <Dropdown
                  options={saasAvailableLocales.map((locale) => {
                    return {
                      label: (
                        <div className="flex items-center">
                          <img
                            src={locale.icon}
                            alt="flag"
                            className="mr-2 w-4 h-4 object-cover"
                          />
                          {locale.name}
                        </div>
                      ),
                      value: locale.code,
                    };
                  })}
                  onChange={handleChangeLocale}
                  value={locale}
                  placeholder={loc("Choisissez une option", lang)}
                  className=" max-w-lg"
                />
              </div>
            </div>
          )}
          <button
            onClick={saveInfos}
            className={`bg-branding-500 w-56 rounded-md my-4 h-10 ${
              disabled ? "opacity-50 cursor-default" : ''
            }`}
            disabled={disabled}
          >
            <p className="text-white">{loc("Sauvegarder", lang)}</p>
          </button>{" "}
        </div>
      </div>
    </div>
  );
}

export default Infos;

import { Component } from "react";
import Header from "./Header";
import Pannel from "./Pannel";
import { loc, ifNanReturnZero } from "../../helpers";
import { DownloadCSV, NbModels, SelectColumnFilterDate } from "./Table";
import { NavAnalytics } from "./NavAnalytics";
import { FilterMoba } from "./FilterMoba";
import AnalyticsCar from "./AnalyticsCar";
import AnalyticsUser from "./AnalyticsUser";
import AnalyticsBoxes from "./AnalyticsBoxes";
import LoadingSpinner from "../components/LoadingSpinner";
import { th } from "date-fns/locale";

class Analytics extends Component {
	constructor(props) {
		super(props);
		this.state = {
			moba: props.moba,
			certificates: null,
			average_data: null,
      average_data_global: null,
			average_soh: null,
			average_odometer: null,
			average_degradation: null,
			average_age: null,
			average_soh_reference: null,
			average_odometer_reference: null,
			average_degradation_reference: null,
			average_age_reference: null,
			nb_diagnostics: null,
			nb_diagnostics_reference: null,
			nb_models: null,
			nb_models_reference: null,
			sites: props.moba.all_sites,
			open: false,
			siteHover: false,
			opacity: "opacity-50",
			days: [],
			show: false,
			startDate: new Date(new Date().getFullYear() - 5, 0, 1),
			endDate: new Date(),
      filters: {},
			toPrint: "Date"
		};

		this.setShow = show => {
			this.setState({
				show: show,
			});
		};

		this.setToPrint = toPrint => {
			this.setState({
				toPrint: toPrint,
			});
		};

		this.setDays = days => {
			this.setState({
				days: days,
			});
		};

		this.handleClickOutside = event => {
			if (this.props.page === 'car') return;
			//if class auth-modal has not the class hidden and the user clicks outside the modal, the modal closes
			if (!event.target.closest(".auth-modal") && !event.target.closest('.date-modal')) {
				this.setState({
					show: false,
					open: false,
				});
			}
		};

		this.updateData = (global_average_data, reference = false) => {
      
      this.setState({
        average_soh: global_average_data.soh?.avg,
        average_age: global_average_data.age?.avg,
        average_degradation: global_average_data.degradation?.avg,
        average_odometer: global_average_data.odometer?.avg,
        nb_diagnostics: global_average_data.diagsCount,
        nb_models: global_average_data.modelsCount,
      });
      if (reference) {
        this.setState({
          average_soh_reference: global_average_data.soh?.avg,
          average_age_reference: global_average_data.age?.avg,
          average_degradation_reference: global_average_data.degradation?.avg,
          average_odometer_reference: global_average_data.odometer?.avg,
          nb_diagnostics_reference: global_average_data.diagsCount,
          nb_models_reference: global_average_data.modelsCount,
        });
      }
			
		};

		this.changeFiltersAnalytics = (filters) => {
      if (filters === undefined || filters === null) {
        filters = {};
      }
      const newFilters = {
        ...this.state.filters,
        ...filters
      };
      this.setState({
        filters: newFilters,
      });
      

      this.state.moba.filterCertificates(newFilters);
      this.state.moba.calculateAverageData();
      this.setState({
        certificates: this.state.moba.certificates_filtered,
        average_data: this.state.moba.average_data,
      });
      const globalAverageData = this.state.moba.calculateGlobalAverageData();
      this.updateData(globalAverageData);
		};

		this.columns = [
			{
				Header: loc("Marque", this.props.lang),
				accessor: "brand",
				Cell: row => (
					<p className="font-bold text-grey-800">
						{row.row.original["brand"]}
					</p>
				),
			},
			{
				Header: loc("Modèle", this.props.lang),
				accessor: "model",
			},
			{
				Header: loc("# Diagnostics", this.props.lang),
				accessor: "number",
			},
			{
				Header: loc("Âge moyen", this.props.lang),
				accessor: "age_avg",
				Filter: FilterMoba,
				filter: (rows, col_id, filterValue) => {
          this.changeFiltersAnalytics(filterValue);
          return rows;
        },
				moba: this.state.moba,
        lang: this.props.lang,
        filters: ["SITE", "TEAM", "DIAG_COST", "COUNTRY"],
        filterFromTableValues: false
			},
			{
				Header: loc("SOH moyen", this.props.lang),
				accessor: "soh_avg",
				lang: this.props.lang,
				Filter: SelectColumnFilterDate, // new
				filterAnalytics: true,
				filter: (rows, id, filterValue) => {
					return rows.filter(row => {
						const rowValue = row.values[id];
						return filterValue.includes(rowValue);
					});
				}
			},
			{
				Header: loc("Km moyen", this.props.lang),
				accessor: "odometer_avg",
				Filter: NbModels,
				type: loc("modèles", this.props.lang),
				lang: this.props.lang,
			},
			{
				Header: loc("Dégradation moyenne", this.props.lang),
				accessor: "degradation_avg",
				Filter: DownloadCSV,
        type: loc("Analytics", this.props.lang),
				lang: this.props.lang,
				id: "download-csv",
			},
		];

		document.addEventListener("click", this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.handleClickOutside);
	}

	componentDidMount() {
    this.state.moba.updateInfos(async () => {
      this.state.moba.fetchCertificatesAnalytics(() => {
        this.state.moba.calculateAverageData();
        this.setState({
          certificates: this.state.moba.certificates_filtered,
          average_data: this.state.moba.average_data,
        });
        const globalAverageData = this.state.moba.calculateGlobalAverageData();
        this.updateData(globalAverageData, true);
      });
    });
	}

	render() {
		if (this.state.average_data === null || this.loadingStack > 0)
			return (
				<div className="flex flex-row min-h-screen m-0">
					<Pannel type="analytics" lang={this.props.lang} />
					<LoadingSpinner/>
				</div>
			);
		else
			return (
				<div className="flex h-full min-h-screen overflow-y-hidden">
					<Pannel type="analytics" lang={this.props.lang} />
					<div className="flex flex-col flex-grow">
						<Header
							connected={true}
							moba={this.props.moba}
							lang={this.props.lang}
						/>
						<div className="mt-2 h-full">
							<div className="flex-col flex flex-1 h-full">
								<div className="flex flex-1">
									<div className="flex h-full flex-col w-full mx-8">
										<p className="font-aeonik text-4xl mb-4">
											Analytics
										</p>
										<div className="flex mb-2">
											<NavAnalytics
												lang={this.props.lang}
												page={this.props.page}
											/>
										</div>
										{this.props.page === "car" && (
											<AnalyticsCar
												data={this.state.average_data}
												columns={this.columns}
												changeFiltersAnalytics={
													this.changeFiltersAnalytics
												}
												lang={this.props.lang}
												type={loc(
													"modèles",
													this.props.lang
												)}
												analyticsStat={
													{
														nb_models: this.state.nb_models,
														nb_models_reference:
															this.state
																.nb_models_reference,
														nb_diagnostics:
															this.state.nb_diagnostics,
														nb_diagnostics_reference:
															this.state
																.nb_diagnostics_reference,
														average_soh:
															this.state.average_soh,
														average_age:
															this.state.average_age,
														average_degradation:
															this.state
																.average_degradation,
														average_odometer:
															this.state.average_odometer,
														average_soh_reference:
															this.state
																.average_soh_reference,
														average_age_reference:
															this.state
																.average_age_reference,
														average_degradation_reference:
															this.state
																.average_degradation_reference,
														average_odometer_reference:
															this.state
																.average_odometer_reference,
													}
												}
												moba={this.state.moba}
											/>)}
										{this.props.page === "teams" && (
											<AnalyticsUser
												moba={this.props.moba}
												lang={this.props.lang}
												open={this.state.open}
												siteHover={this.state.siteHover}
												setState={this.setState.bind(this)}
												sites={this.state.sites}
												opacity={this.state.opacity}
												days={this.state.days}
												setDays={this.setDays.bind(this)}
												show={this.state.show}
												setShow={this.setShow.bind(this)}
												startDate={this.state.startDate}
												endDate={this.state.endDate}
												toPrint={this.state.toPrint}
												setToPrint={this.setToPrint}
												onChange={this.onChange}
											/>
										)}
										{this.props.page === "graphs" && (
											<AnalyticsBoxes
												moba={this.props.moba}
												lang={this.props.lang}
												open={this.state.open}
												siteHover={this.state.siteHover}
												setState={this.setState.bind(this)}
												sites={this.state.sites}
												opacity={this.state.opacity}
												days={this.state.days}
												setDays={this.setDays.bind(this)}
												show={this.state.show}
												setShow={this.setShow.bind(this)}
												startDate={this.state.startDate}
												endDate={this.state.endDate}
												toPrint={this.state.toPrint}
												setToPrint={this.setToPrint}
												onChange={this.onChange}
											/>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
	}
}

export default Analytics;

import { loc } from "../../helpers";
import { useHistory } from "react-router-dom";
import { useState } from "react";

export function NavAnalytics(props) {
	const history = useHistory();
	const [currentPage, setCurrentPage] = useState(props.page);
	return (
		<div className="bg-grey-100 border border-1 p-0.5 rounded-md flex flex-row h-10 items-center pl-1">
			<button
				onClick={() => {
					if (currentPage !== "car") {
						setCurrentPage("car");
						history.push("/car");
					}
				}}
			>
				<div
					className={
						"mr-4 text-aeonik text-grey-500 text-sm h-8 flex flex-row items-center w-full " +
						(currentPage === "car"
							? "bg-white shadow-sm rounded-md"
							: "")
					}
				>
					<p className="m-auto">{loc("Voiture", props.lang)}</p>
				</div>
			</button>
			<button
				onClick={() => {
					if (currentPage !== "teams") {
						setCurrentPage("teams");
						history.push("/teams");
					}
				}}
			>
				<div
					className={
						"mr-4 text-aeonik text-grey-500 text-sm h-8 flex flex-row items-center w-full " +
						(currentPage === "teams"
							? "bg-white shadow-sm rounded-md"
							: "")
					}
				>
					<p className="m-auto">{loc("Équipes", props.lang)}</p>
				</div>
			</button>
			<button
				onClick={() => {
					if (currentPage !== "graphs") {
						setCurrentPage("graphs");
						history.push("/graphs");
					}
				}}
			>
				<div
					className={
						"mr-4 text-aeonik text-grey-500 text-sm h-8 flex flex-row items-center w-full " +
						(currentPage === "graphs"
							? "bg-white shadow-sm rounded-md"
							: "")
					}
				>
					<p className="m-auto">{loc("Graphs", props.lang)}</p>
				</div>
			</button>
		</div>
	);
}

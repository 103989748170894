import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useCookies } from "react-cookie";
import HeaderLogin from "./HeaderLogin";
import Api from "../../Api";
import ReactCodeInput from "react-code-input";
import Next from "./Next";
import { loc } from "../../helpers";
import FooterLogin from "../components/FooterLogin";
import { useToast } from "../../contexts/ToastContext";
import mixpanel from "mixpanel-browser";

export default function Code(props) {
  const [email, setEmail] = useState(props.moba.email);
  const inputprops = {
    inputStyle: {
      fontFamily: "monospace",
      margin: "8px",
      MozAppearance: "textfield",
      width: "50px",
      fontSize: "20px",
      height: "50px",
      backgroundColor: "white",
      color: "grey",
      border: "1px solid #D0D5DD",
      borderRadius: "8px",
      textAlign: "center",
      caretColor: "transparent",
    },
  };
  const [pinCode, setPinCode] = useState("");
  const handlePinChange = (pinCode) => {
    setPinCode(pinCode);
  };
  const { showToast } = useToast();
  const inputRef = useRef(null);
  const [minerCookies, setMinerCookie, removeMinerCookie] = useCookies([
    "miner",
  ]);

  useEffect(() => {
    if (null !== inputRef.current) {
      document
        .querySelectorAll("input")
        .forEach((node) => node.setAttribute("placeholder", "•"));
    }
    if (props.moba.email === "") {
      window.location.href = "/login";
    }
  }, []);

  const loginSuccess = (result) => {
    if (result.cookie && !minerCookies.miner) {
      setMinerCookie("miner", result.cookie, { path: "/" });
    }
    // Reload page with querystring login=success
    window.location.href = "/?login=success";
  };

  const loginError = () => {
    showToast(loc("Erreur", props.lang), loc("Code invalide", props.lang));
    setPinCode("");
    if (inputRef.current.textInput[0]) inputRef.current.textInput[0].focus();
    inputRef.current.state.input[0] = "";
    inputRef.current.state.input[1] = "";
    inputRef.current.state.input[2] = "";
    inputRef.current.state.input[3] = "";
    inputRef.current.state.input[4] = "";
    inputRef.current.state.input[5] = "";
  };

  const login = async () => {
    new Api().fetch(
      "mobaSignin",
      {
        email: props.moba.email,
        code: pinCode,
      },
      (result) => {
        if (result.err === 0) {
          loginSuccess(result);
        } else {
          loginError();
        }
      }
    );
  };

  return (
    <div className="flex flex-col m-0 min-h-screen">
      <HeaderLogin lang={props.lang}/>
      <form
        className="flex flex-col mx-auto font-aeonik"
        onSubmit={async (event) => {
          //Prevent page reload
          event.preventDefault();
          login();
        }}
      >
        <h2 className="font-medium text-2xl w-128 text-center mt-40">
          {loc("Code de sécurité", props.lang)}
        </h2>
        <h3 className="text-base w-128 text-center mt-4 text-grey-600">
          {loc("Nous avons envoyé un code de sécurité à", props.lang)}
          <span className=" font-bold"> {props.moba.email}</span>.{" "}
          {loc(
            "Veuillez saisir ci-dessous ce code pour vous identifier.",
            props.lang
          )}
        </h3>
        <div className="mx-auto mt-4">
          <ReactCodeInput
            ref={inputRef}
            onChange={handlePinChange}
            id="pinCode"
            type="text"
            fields={6}
            value={pinCode}
            {...inputprops}
            className="react-code-input"
          />
        </div>
        <div className="mt-4">
          <Next disabled={pinCode.length < 6} lang={props.lang}></Next>
        </div>
        <a
          href="/login"
          className=" text-sm text-center mt-4 text-branding-500 underline"
        >
          {loc("Essayer avec un autre e-mail", props.lang)}
        </a>
      </form>
      <FooterLogin lang={props.lang} />
    </div>
  );
}

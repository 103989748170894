import { Component, useEffect, useState, useMemo } from "react";
import { Doughnut } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { loc } from "../../helpers";
import Tooltip from "../components/Tooltip";
import LoadingSpinner from "../components/LoadingSpinner";

Chart.register(CategoryScale);

export default function PieChart(props) {
  const [pieData, setPieData] = useState(null);

  const fullDataAmount = useMemo(() => {
    return pieData?.datasets[0].data?.reduce((a, b) => a + b, 0);
  }, [pieData]);

  useEffect(() => {
    createPie(props);
  }, [props.type, props.percent_pie]);

  const createPie = (props) => {
    if (props.loading) {
      return <LoadingSpinner />;
    }
    if (props.type === "model") {
      let sorted_models = Object.keys(props.percent_pie).sort((a, b) => {
        return props.percent_pie[b] - props.percent_pie[a] ? 1 : -1;
      });
      //convert sorted_models to a dict
      let sorted_models_dict = {};
      for (let i = 0; i < sorted_models.length; i++) {
        sorted_models_dict[sorted_models[i]] =
          props.percent_pie[sorted_models[i]];
      }
      sorted_models = sorted_models_dict;
      //we take the 4 first models
      let sorted_models_4 = {};
      let i = 0;
      for (let key in sorted_models) {
        if (i < 4) {
          sorted_models_4[key] = sorted_models[key];
        }
        i++;
      }
      sorted_models = sorted_models_4;

      //we create an object with the other models
      sorted_models[loc("Autres", props.lang)] = 0;

      Object.keys(props.percent_pie).forEach((model) => {
        if (!Object.keys(sorted_models).includes(model)) {
          sorted_models[loc("Autres", props.lang)] += props.percent_pie[model];
        }
      });
      //we add the other sites to the sorted models
      setPieData({
        labels: Object.keys(sorted_models).map((model) => {
          return model + "                             ";
        }),
        datasets: [
          {
            label: "Models",
            backgroundColor: [
              "#EB3800",
              "#FF9922",
              "#60DDFF",
              "#00D3AA",
              "#00886E",
            ],
            data: Object.keys(sorted_models).map((key) => {
              return sorted_models[key];
            }),
          },
        ],
      });
    } else if (props.type === "soh") {
      setPieData({
        labels: [
          loc("SoH inférieur à 65%", props.lang),
          loc("SoH compris entre 65% et 75%", props.lang),
          loc("SoH compris entre 75% et 85%", props.lang),
          loc("SoH compris entre 85% et 95%", props.lang),
          loc("SoH compris entre 95% et 100%", props.lang),
        ],
        datasets: [
          {
            label: loc("État de santé", props.lang),
            data: props.percent_pie,
            backgroundColor: [
              "#EB3800",
              "#FF9922",
              "#60DDFF",
              "#00D3AA",
              "#00886E",
            ],
          },
        ],
      });
    } else if (props.type === "age") {
      setPieData({
        labels: [
          loc("Age inférieur à 1 an", props.lang),
          loc("Age compris entre 1 et 2 ans", props.lang),
          loc("Age compris entre 2 et 3 ans", props.lang),
          loc("Age compris entre 3 et 4 ans", props.lang),
          loc("Age supérieur à 4 ans", props.lang),
        ],
        datasets: [
          {
            label: "Age",
            data: props.percent_pie,
            backgroundColor: [
              "#EB3800",
              "#FF9922",
              "#60DDFF",
              "#00D3AA",
              "#00886E",
            ],
          },
        ],
      });
    } else if (props.type === "odometer") {
      setPieData({
        labels: [
          loc("Kilométrage inférieur à 10 000", props.lang),
          loc("Kilométrage compris entre 10 000 et 30 000", props.lang),
          loc("Kilométrage compris entre 30 000 et 80 000", props.lang),
          loc("Kilométrage compris entre 80 000 et 150 000", props.lang),
          loc("Kilométrage supérieur à 150 000", props.lang),
        ],
        datasets: [
          {
            label: loc("Kilométrage", props.lang),
            data: props.percent_pie,
            backgroundColor: [
              "#EB3800",
              "#FF9922",
              "#60DDFF",
              "#00D3AA",
              "#00886E",
            ],
          },
        ],
      });
    } else if (props.type === "sites") {
      let sorted_models = Object.keys(props.percent_pie).sort((a, b) => {
        return props.percent_pie[b] - props.percent_pie[a];
      });
      //convert sorted_models to a dict
      let sorted_models_dict = {};
      for (let i = 0; i < sorted_models.length; i++) {
        sorted_models_dict[sorted_models[i]] =
          props.percent_pie[sorted_models[i]];
      }
      sorted_models = sorted_models_dict;
      let sorted_models_16 = {};
      let i = 0;
      for (let key in sorted_models) {
        if (i < 16) {
          sorted_models_16[key] = sorted_models[key];
        }
        i++;
      }
      sorted_models = sorted_models_16;

      //we create an object with the other models
      sorted_models["Autres sites"] = 0;

      Object.keys(props.percent_pie).forEach((model) => {
        if (!Object.keys(sorted_models).includes(model)) {
          sorted_models["Autres sites"] += props.percent_pie[model];
        }
      });
      if (sorted_models["Autres sites"] === 0) {
        delete sorted_models["Autres sites"];
      }
      //we add the other sites to the sorted models
      setPieData({
        labels: Object.keys(sorted_models).map((model) => {
          return model;
        }),
        datasets: [
          {
            label: loc("Sites", props.lang),
            backgroundColor: [
              "#B54708",
              "#FEF0C7",
              "#FEE4E2",
              "#E4E7EC",
              "#EFF8FF",
              "#F4F3FF",
              "#E6FBF7",
              "#ECFDF3",
              "#F4F3FF",
              "#00A988",
              "#00D3AA",
              "#B3F2E6",
              "#60DDFF",
              "#B3F2E6",
              "#00D3AA",
              "#00A988",
              "#EB3800",
            ],
            data: Object.keys(sorted_models).map((key) => {
              return sorted_models[key];
            }),
          },
        ],
      });
    } else {
      setPieData({
        labels: [
          loc("SoH inférieur à 65%", props.lang),
          loc("SoH compris entre 65% et 75%", props.lang),
          loc("SoH compris entre 75% et 85%", props.lang),
          loc("SoH compris entre 85% et 95%", props.lang),
          loc("SoH compris entre 95% et 100%", props.lang),
        ],
        datasets: [
          {
            label: loc("État de santé", props.lang),
            data: props.percent_pie,
            backgroundColor: [
              "#EB3800",
              "#FF9922",
              "#60DDFF",
              "#00D3AA",
              "#00886E",
            ],
          },
        ],
        position: "right",
      });
    }
  };

  if (props.loading) {
    return <LoadingSpinner />;
  } else if (!props.percent_pie || !pieData) {
    return (
      <div className="flex justify-center items-center">
        <div className="m-auto text-xl">
          {loc("Données indisponibles", props.lang)}
        </div>
      </div>
    );
  } else if (fullDataAmount === 0) {
    return (
      <div className="h-full flex flex-1 justify-center items-center self-center">
        {loc("Aucune donnée disponible", props.lang)}
      </div>
    );
  } else if (props.type === "sites") {
    return (
      <div className="">
        <div className="w-full">
          <Doughnut
            data={pieData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  display: props.type === "sites" ? false : true,
                  position: "right",
                },
                title: {
                  display: false,
                  text: "Chart.js Doughnut Chart",
                },
              },
            }}
          ></Doughnut>
        </div>
        {props.type === "sites" && props.showLegend && (
          <div className="mt-10 grid grid-flow-row grid-cols-1 lg:grid-cols-2 max-h-56 overflow-scroll gap-x-3 gap-y-4">
            {pieData.labels.map((key, index) => {
              return (
                <div className={`flex items-center`} key={key}>
                  <div
                    className={"w-2 h-2 min-w-2 rounded-full"}
                    style={{
                      backgroundColor:
                        pieData.datasets[0].backgroundColor[index],
                      minWidth: "0.5rem",
                    }}
                  ></div>
                  <Tooltip
                    message={key}
                    className="truncate"
                    overflowOnly={true}
                  >
                    <p className="ml-2 font-inter text-grey-600 text-sm truncate">
                      {key}
                    </p>
                  </Tooltip>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="flex mt-2 items-center">
        <div className="w-64 h-48">
          <Doughnut
            data={pieData}
            height={336}
            width={336}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                  position: "right",
                },
                title: {
                  display: false,
                  text: "Chart.js Doughnut Chart",
                },
                tooltip: {
                  enabled: true,
                  callbacks: {
                    label: function (context) {
                      return " " + context.parsed;
                    },
                  },
                },
              },
            }}
          ></Doughnut>
        </div>
        {props.showLegend && (
          <div className="w-64 flex flex-col gap-2">
            {pieData.labels.map((key, index) => {
              return (
                <div className="flex items-center" key={key}>
                  <div
                    className={"w-2 h-2 rounded-full"}
                    style={{
                      backgroundColor:
                        pieData.datasets[0].backgroundColor[index],
                    }}
                  ></div>
                  <p
                    className="ml-2 font-inter text-grey-600 text-sm font-aeonik leading-6"
                    style={{
                      color: pieData.datasets[0].backgroundColor[index],
                    }}
                  >
                    {key}
                  </p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

import { useMemo } from "react";
import { loc } from "../../helpers";
import Tooltip from "./Tooltip";

const PlusBubble = ({ data, field, lang }) => {
  const tooltipText = useMemo(() => {
    return data
      .map((el) => (field ? loc(String(el[field]).trim(), lang) : loc(String(el).trim(), lang)))
      .join(", ");
  }, [data, field, lang]);

  const showBubble = useMemo(() => {
    return data.length > 1;
  }, [data]);

  return (
    <>
      {showBubble && (
        <Tooltip message={tooltipText} position="left">
          <div
            className="group cursor-help h-7 w-7 rounded-full shadow-xxs p-2 border-1 border-grey-200 ml-2 flex justify-center items-center"
          >
            <p>+{data.length - 1}</p>
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default PlusBubble;

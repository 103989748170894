import React, { createContext, useState, useContext, useRef } from 'react';
import Toast from './Toast';

export const ToastContext = createContext();

export function ToastProvider({ children }) {
  const [toast, setToast] = useState(null);
  const toastTimeout = useRef(null);

  const showToast = (title, message, type) => {
    if (toastTimeout.current) {
      clearTimeout(toastTimeout.current);
    }
    setToast(null);
    setTimeout(() => {
      setToast({ title, message, type });
      toastTimeout.current = setTimeout(() => {
        setToast(null);
      }, 5000);
    }, 100);
  };

  const hideToast = () => {
    if (toastTimeout.current) {
      clearTimeout(toastTimeout.current);
    }
    setToast(null);
  };

  return (
    <ToastContext.Provider value={{ showToast, hideToast }}>
      {children}
      {toast && (
        <Toast
          title={toast.title}
          message={toast.message}
          type={toast.type}
          onClose={hideToast}
        />
      )}
    </ToastContext.Provider>
  );
}

export function useToast() {
  return useContext(ToastContext);
}
import { Stat } from "./Analytics";
import { StatisticBox } from "./StatisticBox";
import { loc, ifNanReturnZero, convertMiles } from "../../helpers";

export function AnalyticsStat(props) {
	return (
		<div className="flex flex-col ml-2 text-xs h-full gap-5">
			<StatisticBox
				title={loc("Modèles", props.lang)}
				value={props.nb_models}
                lang={props.lang}
			/>
			<StatisticBox
				title={loc("Diagnostics", props.lang)}
				value={props.nb_diagnostics}
                lang={props.lang}
			/>
			<StatisticBox
				title={loc("Kilométrage moyen", props.lang)}
				value={Math.round(props.average_odometer)}
				unit={props.unit}
        lang={props.lang}
			/>
			<StatisticBox
				title={loc("SOH moyen", props.lang)}
				value={Math.round(props.average_soh * 10) / 10}
				unit="%"
        lang={props.lang}
			/>
			<StatisticBox
				title={loc("Âge moyen", props.lang)}
				value={Math.round(props.average_age)}
				unit="month(s)"
        lang={props.lang}
			/>
		</div>
	);
}

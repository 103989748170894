import React from "react";
import { useHistory } from "react-router-dom";
import { loc } from "../../helpers";
import {
	HouseIcon,
	StarIcon,
	AnalyticsIcon,
	TeamIcon,
	GreenBar,
} from "./Icons";

function NavigationItem({ route, label, IconClass, active }) {
	var history = useHistory();
  const activeColor = 'text-branding-500'
  const inactiveColor = 'text-moba-white'

  const color = active ? activeColor : inactiveColor

  return (
    <button
      className={
        `items-center flex ${color} hover:text-branding-200`
      }
      onClick={() => {
        history.push(route);
      }}
    >
      <div className={`flex items-center ml-2 gap-5`}>
        { active ? <GreenBar /> : null }
        <IconClass /> 
        <p>{label}</p>
      </div>
    </button>
  );
}

export default function Pannel(props) {
	return (
		<div className="flex flex-col flex-none w-80 bg-linear-gradient-1 from-teal-300 to-teal-900 font-aeonik">
			<div className="h-32 text-white bg-moba-green flex flex-col font-bold">
				<h1 className="m-auto text-xl text-center">MOBA CERTIFY PRO</h1>
			</div>
			<div className="flex flex-col text-base gap-12 ml-4 mt-5">
        <NavigationItem
          route="/dashboard"
          label={loc("Tableau de bord", props.lang)}
          IconClass={HouseIcon}
          active={props.type === "dashboard"}
        />
        <NavigationItem
          route="/certificates"
          label={loc("Certificats", props.lang)}
          IconClass={StarIcon}
          active={props.type === "certificates"}
        />
        <NavigationItem
          route="/car"
          label={loc("Analytics", props.lang)}
          IconClass={AnalyticsIcon}
          active={props.type === "analytics"}
        />
        <NavigationItem
          route="/management"
          label={loc("Organisation", props.lang)}
          IconClass={TeamIcon}
          active={props.type === "management"}
        />
			</div>
		</div>
	);
}

import React from "react";
import { loc } from "../../helpers";
import Tooltip from "../components/Tooltip";

export function InputForm(props) {
	return (
		<div className="flex flex-col">
      <label className="text-sm ml-0 mr-auto mt-4 mb-2 font-aeonik text-gray-600 font-medium">
				{loc(props.title, props.lang)}
				{props.required && (
					<span style={{color:'red'}}> *</span>
				)}
			</label>
      <Tooltip
        className="w-full"
        message={props.disabledReason}
        disabled={!props.disabled || !props.disabledReason}
      >
        <input
          type="text"
          className={`flex w-full px-4 border border-1 border-grey-300 h-11 rounded-lg shadow-xs placeholder:font-aeonik disabled:cursor-not-allowed disabled:bg-grey-100`}
          defaultValue={props.defaultValue}
          onChange={props.handleChange}
          name={props.name}
          required={props.required}
          placeholder={loc(props.placeholder, props.lang)}
          disabled={props.disabled}
        />
      </Tooltip>
		</div>
	);
}

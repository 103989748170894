import { useMemo } from "react";
import { loc } from "../../helpers";

export default function FilteredView({ itemsFiltered, setItemsFiltered, filterType, lang }) {

  const textByType = {
    'SITES': {
      'singular': loc("Le site sélectionné est:", lang),
      'plural': loc("Les sites sélectionnés sont:", lang),
      'empty': loc("Aucun site sélectionné", lang)
    },
    'TEAMS': {
      'singular': loc("L'équipe sélectionnée est:", lang),
      'plural': loc("Les équipes sélectionnées sont:", lang),
      'empty': loc("Aucune équipe sélectionnée", lang)
    },
    'ROLES': {
      'singular': loc("Le rôle sélectionné est:", lang),
      'plural': loc("Les rôles sélectionnés sont:", lang),
      'empty': loc("Aucun rôle sélectionné", lang)
    },
    'COUNTRIES': {
      'singular': loc("Le pays sélectionné est:", lang),
      'plural': loc("Les pays sélectionnés sont:", lang),
      'empty': loc("Aucun pays sélectionné", lang)
    },
    'DIAGNOSTICS': {
      'singular': loc("Le diagnostic sélectionné est:", lang),
      'plural': loc("Les diagnostics sélectionnés sont:", lang),
      'empty': loc("Aucun diagnostic sélectionné", lang)
    },
    'DELEGATORS': {
      'singular': loc("Le client sélectionné est:", lang),
      'plural': loc("Les clients sélectionnés sont:", lang),
      'empty': loc("Aucun client sélectionné", lang)
    },
    'DELEGATIONS': {
      'singular': loc("La société d'inspection sélectionnée est:", lang),
      'plural': loc("Les sociétés d'inspection sélectionnées sont:", lang),
      'empty': loc("Aucune société d'inspection sélectionnée", lang)
    }
  }

  
    const itemsFilteredLength = itemsFiltered ? itemsFiltered.length : 0
    const itemsFilteredNames = itemsFiltered ? itemsFiltered.map(item => item.name || item.label || item) : []

    const itemFilteredLabelText = useMemo(() => {
      if(itemsFilteredLength === 0) return textByType[filterType].empty
      if(itemsFilteredLength === 1) return textByType[filterType].singular
      return textByType[filterType].plural
    }, [itemsFilteredLength, filterType])

    const itemFilteredValueText = useMemo(() => {
      if(itemsFilteredLength === 0) return null
      const firstEls = itemsFilteredNames.slice(0, 3);
      let andMoreTxt;
      if (itemsFilteredLength === 4) {
        andMoreTxt = ("... " + loc("et [nItems] autre", lang));
      } else if (itemsFilteredLength > 4) {
        andMoreTxt = ("... " + loc("et [nItems] autres", lang));
      } else {
        andMoreTxt = "";
      }
      andMoreTxt = andMoreTxt.replace("[nItems]", itemsFilteredLength - 3);
      return firstEls.join(", ") + andMoreTxt;
    }, [itemsFilteredLength, itemsFilteredNames, lang])

      if(!itemsFiltered) return null
      return (
          <div className="mt-4 flex bg-blue-gray-500 h-12 rounded-md items-center mr-6" style={
              {
                  padding: "12px 12px 12px 12px !important",
                  width: "fit-content",
              }
          }>
              <p className="text-aeonik ml-4 mr-1 text-xs my-auto text-grey-400" style={
                  {
                      padding: "0 0 0 0 !important",
                  }
              }>{ itemFilteredLabelText }</p>
              {itemsFilteredLength != 0 &&
                  (
                  <p className="text-aeonik text-xs mr-2 my-auto text-blue-gray-700">{ itemFilteredValueText }</p>
                  )
              }
              <img className="mr-2 cursor-pointer" src={"/assets/cross.svg"} onClick={() => setItemsFiltered(null)} />
          </div >
      )

    return null
    }
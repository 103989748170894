import { Component } from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { loc } from "../../helpers";

Chart.register(CategoryScale);

class LineChart extends Component {
	constructor(props) {
		super(props);
		//get width of the screen
		var width = window.innerWidth;
		if (width >= 1536) var max_team_name_length = 18;
		else var max_team_name_length = 7;
		this.createLineChart = props => {
			this.getRandomColor = () => {
				var letters = "0123456789ABCDEF";
				var color = "#";
				for (var i = 0; i < 6; i++) {
					color += letters[Math.floor(Math.random() * 16)];
				}
				return color;
			};

			if (props.type === "diags") {
				var team_number = 0;
				var color_by_team = [
					"#00A988",
					"#00D3AA",
					"#B3F2E6",
					"#EB3800",
					"#2AD300",
					"#00886E",
					"#60DDFF",
					"#007AD3",
					"#8B4ED9",
					"#FF6711",
					"#F4E660",
					"#7086FA",
					"#00D3AD",
					"#FF9922",
				];
				this.data = {
					labels: this.getLast12MonthsLabels(),
					datasets: Object.keys(props.data).map(team => {
						team_number += 1;
						return {
							label:
								team.length > max_team_name_length
									? team.slice(0, max_team_name_length) +
									"..."
									: team,
							data: props.data[team],
							backgroundColor: color_by_team[team_number - 1],
							borderColor: color_by_team[team_number - 1],
						};
					}),
				};
			} else {
				const DATA_COUNT = 48;
				const labels = [];
				var i = 0;
				while (i < DATA_COUNT) {
					labels.push(i.toString());
					i += 12;
				}

				const equation = props.data[0];

				const datapoints = labels.map(x => {
					//convert x to a number
					x = Number(x);
					var y = equation[0] * x + equation[1];
					return y;
				});

				this.data = {
					labels: labels.map(x => {
						return x.toString() + loc(" mois", this.props.lang);
					}),
					datasets: [
						{
							label: loc("Dégradation", this.props.lang),
							data: datapoints,
							fill: false,
							cubicInterpolationMode: "monotone",
							tension: 0,
							backgroundColor: "#00D3AA",
							borderColor: "#00D3AA",
							//abscissa label
						},
					],
				};
			}
		};
	}
    getLast12MonthsLabels() {
        var months = []
        var lang = this.props.lang.split('_')[0];
        for (var i = 0; i < 12; i++) {
            var date = new Date();
            date.setMonth(date.getMonth() - i);
            let monthLabel = date.toLocaleString(lang, { month: 'short' });
            if (date.getMonth() === 0) {
                monthLabel = `${monthLabel} (${date.getFullYear()})`
            }
            months.push(loc(monthLabel, this.props.lang))
        }
        return months.reverse()
    }
	render() {
		this.createLineChart(this.props);
		return (
			<div
				className="flex w-10/12 m-auto justify-center items-center h-72"
				style={{ height: "220px" }}
			>
				<Line
					data={this.data}
					options={{
						responsive: true,
						maintainAspectRatio: false, // add this property
						scales: {
							y:
								this.props.type !== "diags"
									? {
										ticks: {
											min: 0,
											max: this.max, // Your absolute max value
											callback: function (value) {
												return (
													(
														(value / this.max) *
														100
													).toFixed(0) + "%"
												); // convert it to percentage
											},
										},
										title: {
											display: true,
											text: "SOH",
										},
									}
									: {
										title: {
											display: true,
											text: loc(
												"Nombre de diagnostics",
												this.props.lang
											),
										},
										min: 0,
									},
						},
						plugins: {
							legend: {
								display: false,
							},
							title: {
								display: false,
							},
						},
						tension: 0.4,
						fill: {
							target: 'origin',
							above: 'rgba(0, 211, 170, 0.1)',
						  }
					}}
				></Line>
				{/* <div className="px-2">
						{
						this.data.datasets
						.map((key, index) => {
							return (
								<div className="flex items-center">
									<div
										className={
											"w-2 h-2 rounded-full "
										}
										style={{
											backgroundColor:
												key.backgroundColor,
										}}
									></div>
									<p className="ml-2 font-inter text-grey-600 text-sm flex-1">
										{
											key.label
										}
									</p>
								</div>
							);
						})}
				</div> */}
			</div>
		);
	}
}
export default LineChart;

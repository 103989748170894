import React from "react";

const LoadingSpinner = ({ show }) => {
  if (show === false) {
    return;
  }
  return (
    <div className="flex flex-1 justify-center items-center">
      <div
        className=" border-branding-25 h-20 w-20 animate-spin rounded-full border-8 self-center"
        style={{ borderTopColor: "#00D3AA" }}
      />
    </div>
  );
};

export default LoadingSpinner;

import { Component } from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { loc } from "../../helpers";
import { Pagination } from "./Pagination";
import LoadingSpinner from "../components/LoadingSpinner";

Chart.register(CategoryScale);

class BarChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageN: 0,
      pageSize: 6,
      pageCount: 1,
    };

    this.gotoPage = (page) => {
      this.setState({ pageN: page });
    };

    this.previousPage = () => {
      this.setState({ pageN: this.state.pageN - 1 });
    };

    this.nextPage = () => {
      this.setState({ pageN: this.state.pageN + 1 });
    };

    this.canNextPage = () => {
      return this.state.pageN < this.state.pageCount - 1;
    };

    this.canPreviousPage = () => {
      return this.state.pageN > 0;
    };

    this.setPageSize = (size) => {
      this.setState({ pageSize: size });
    };

    this.refreshPageCount = () => {
      this.setState({
        pageCount: Math.ceil(
          Object.keys(this.props.data).length / this.state.pageSize
        ),
      });
    };

    this.getSortedData = () => {
      const dataAsArray = Object.keys(this.props.data).map((key) => {
        return { key, value: this.props.data[key] };
      });
      dataAsArray.sort((a, b) => {
        return b.value - a.value;
      });
      return dataAsArray;
    };

    const colors = [
      "#00D3AA",
      "#19D7B3",
      "#66E5CC",
      "#80E9D5",
      "#B3F2E6",
      "#E6FBF7",
    ];

    this.createChart = (props) => {
      if (props.loading) return;
      const pageSlice = [
        this.state.pageN * this.state.pageSize,
        this.state.pageN * this.state.pageSize + this.state.pageSize,
      ];
      const sortedData = this.getSortedData();
      const dataPage = sortedData.slice(...pageSlice);

      if (props.type === "Dongle") {
        this.data = {
          labels: dataPage.map((dgl) => dgl.key),
          datasets: [
            {
              label: "Dongle",
              data: dataPage.map((dgl) => dgl.value),
              borderColor: "rgb(255, 99, 132)",
              backgroundColor: dataPage.map((dgl, index) => colors[index]),
              borderRadius: 10, // Add this property to make the bars rounded
              barThickness: 40,
            },
          ],
        };
      } else if (props.type === "Team") {
        this.data = {
          labels: dataPage.map((t) => t.key),
          datasets: [
            {
              label: "Team",
              data: dataPage.map((t) => t.value),
              borderColor: "rgb(255, 99, 132)",
              backgroundColor: dataPage.map((t, index) => colors[index]),
              borderRadius: 10, // Add this property to make the bars rounded
              barThickness: 40,
            },
          ],
        };
      } else {
        this.data = {
          labels: dataPage.map((op) => op.key),
          datasets: [
            {
              label: "Operator",
              data: dataPage.map((op) => op.value),
              borderColor: "rgb(255, 99, 132)",
              backgroundColor: dataPage.map((op, index) => colors[index]),
              borderRadius: 10, // Add this property to make the bars rounded
              barThickness: 40,
            },
          ],
        };
      }
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.data !== this.props.data ||
      prevState.pageN !== this.state.pageN ||
      prevState.pageSize !== this.state.pageSize
    ) {
      this.createChart(this.props);
      this.refreshPageCount();
    }
  }

  componentDidMount() {
    if (this.props.loading) return;
    this.createChart(this.props);
    this.refreshPageCount();
  }

  render() {
    var props = this.props;
    const dataLabelsPlugin = {
      id: "dataLabels",
      afterDatasetsDraw: (chart) => {
        const ctx = chart.ctx;

        chart.data.datasets.forEach((dataset, i) => {
          const meta = chart.getDatasetMeta(i);
          if (!meta.hidden) {
            var i = 0;
            meta.data.forEach((bar) => {
              var data = dataset.data[i];
              data =
                data.toString() +
                (" " +
                  (data === 1
                    ? loc("diagnostic", props.lang)
                    : loc("diagnostics", props.lang)));
              // Configure the font style, size, and color
              ctx.fillStyle = "#000";
              ctx.font = "12px Arial";
              //write the data value inside the bar at the very right
              const position = bar.getCenterPoint();
              var maxLabelWidth = 0;
              this.data.labels.forEach((label) => {
                var labelWidth = ctx.measureText(label).width;
                if (labelWidth > maxLabelWidth) {
                  maxLabelWidth = labelWidth;
                }
              });

              var x = bar.x - 100; // 5 is a padding value, adjust as needed
              if (x < 120) x = 120;
              x = Math.max(x, maxLabelWidth);
              const y = position.y + 3;

              // Render the data value inside the bar at the very right
              ctx.fillText(data, x + 10, y);
              i++;
            });
          }
        });
      },
    };
    if (this.props.loading) {
      return (
        <div className="flex h-full flex-1 justify-center items-center">
          <LoadingSpinner />
        </div>
      );
    }
    return this.data?.labels?.length ? (
      <div className="barchart-element h-full overflow-scroll flex flex-column justify-between">
        <Bar
          data={this.data}
          options={{
            scales: {
              x: {
                grid: {
                  display: false,
                },
              },
              y: {
                grid: {
                  display: false,
                },
              },
            },
            indexAxis: "y",
            responsive: true,
            plugins: {
              legend: {
                position: "right",
                display: false,
              },
              title: {
                display: false,
                text: "Chart.js Doughnut Chart",
              },
            },
            elements: {
              point: {
                radius: 0,
              },
            },
          }}
          plugins={[dataLabelsPlugin]}
        ></Bar>
        <Pagination
          gotoPage={this.gotoPage}
          canPreviousPage={this.canPreviousPage}
          previousPage={this.previousPage}
          canNextPage={this.canNextPage}
          nextPage={this.nextPage}
          pageCount={this.state.pageCount}
          state={{ pageIndex: this.state.pageN, pageSize: this.state.pageSize }}
          setPageSize={this.setPageSize}
          lang={this.props.lang}
        />
      </div>
    ) : (
      <div className="h-full flex flex-1 justify-center items-center">
        {loc("Aucune donnée disponible", props.lang)}
      </div>
    );
  }
}
export default BarChart;

import { StatisticBox } from "./StatisticBox";
import { loc } from "../../helpers";

export function UserStat(props) {
	const countRole = (users, role) => {
    return users.filter(
      user => user.role.toLowerCase() === role.toLowerCase()
    ).length;
  }
		

	return (
		<div className="flex flex-col flex-1 h-full gap-5">
			<StatisticBox
				title={loc("Utilisateurs", props.lang)}
				value={props.entity_details.users.length}
                lang={props.lang}
			/>
			<StatisticBox
				title={loc("Super Admins", props.lang)}
				value={countRole(props.entity_details.users, "Super Admin")}
                lang={props.lang}
			/>
			{/* <StatisticBox
				title={loc("Site Admins", props.lang)}
				value={countRole(props.entity_details.users, "Site Admin")}
                lang={props.lang}
			/> */}
			<StatisticBox
				title={loc("Site Managers", props.lang)}
				value={countRole(props.entity_details.users, "Site Manager")}
                lang={props.lang}
			/>
			<StatisticBox
				title={loc("Opérateurs", props.lang)}
				value={countRole(props.entity_details.users, "Opérateur")}
                lang={props.lang}
				last
			/>
		</div>
	);
}

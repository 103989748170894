import { loc } from "../../helpers";
import { useHistory } from "react-router-dom";
import { useState } from "react";

export function NavInfos(props) {
	const history = useHistory();
	return (
		<div className="bg-grey-100 border border-1 p-0.5 rounded-md flex flex-row h-10 items-center pl-1">
			<button
				onClick={() => {
					if (props.currentPage !== "profile") {
						props.setCurrentPage("profile");
					}
				}}
			>
				<div
					className={
						"mr-4 text-aeonik text-grey-500 text-sm h-8 flex flex-row items-center w-full " +
						(props.currentPage === "profile"
							? "bg-white shadow-sm rounded-md"
							: "")
					}
				>
					<p className="m-auto">{loc("Profil", props.lang)}</p>
				</div>
			</button>
			<button
				onClick={() => {
					if (props.currentPage !== "preferences") {
						props.setCurrentPage("preferences");
					}
				}}
			>
				<div
					className={
						"mr-4 text-aeonik text-grey-500 text-sm h-8 flex flex-row items-center w-full " +
						(props.currentPage === "preferences"
							? "bg-white shadow-sm rounded-md"
							: "")
					}
				>
					<p className="m-auto">{loc("Préférences", props.lang)}</p>
				</div>
			</button>
		</div>
	);
}

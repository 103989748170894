import React from "react";
import { loc, getLocalString } from "../../helpers";
import { DecreaseIcon, IncreaseIcon } from "./Icons";
import Tooltip from "../components/Tooltip";

export function StatisticBox({ title, value, value_reference, unit, lang }) {
  const number_certificates_diff = Math.round(
    ((value - value_reference) / value) * 100,
    0
  );
  let format = {};
  if (number_certificates_diff < 0) {
    format.bgColor = "bg-red-light";
    format.textColor = "text-red-dark";
    format.icon = <DecreaseIcon />;
  } else {
    format.bgColor = "bg-green-light";
    format.textColor = "text-green";
    format.icon = <IncreaseIcon />;
  }

  const tooltipMessage = loc("Comparaison avec la même période de l'année précédente", lang);

  return (
    <div
      className={`bg-white border border-grey-200 rounded-lg p-2 flex flex-col shadow-sm w-64`}
    >
      <>
        <p className="text-aeonik text-grey-light">{loc(title, lang)}</p>
        <div className="flex pl-2">
          <div className="flex flex-col text-aenik text-grey-800 text-5xl mt-2 truncate">
            <p>{getLocalString(value, lang) || "-"}</p>
            <p className=" text-grey-500 text-2xl">
              {unit ? unit : title.toLowerCase()}
            </p>
          </div>
          {value_reference && (
            <div className="ml-auto mt-auto">
              <Tooltip
                message={tooltipMessage}
                position="bottom"
              >

                <div
                  className={`items-center flex mt-auto ml-auto rounded-md ${format.bgColor} p-2 rounded-full text-sm`}
                >
                  {format.icon}
                  <p className={format.textColor}>
                    {" "}
                    {number_certificates_diff}%{" "}
                  </p>
                </div>
              </Tooltip>
            </div>
          )}
        </div>
      </>
    </div>
  );
}

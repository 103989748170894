import { Component } from "react";
import Pannel from "./Pannel";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import { loc } from "../../helpers";
import { Divider } from "./Icons";
import Filter from "./Filter";
import { FilterMoba } from "./FilterMoba";
import { DatePicker } from "./DatePicker";
import FilteredView from "./FilteredView";

import mixpanel from "mixpanel-browser";

function arraysAreEqual(array1, array2) {
  if (array1 === array2) {
    return true;
  }
  if (array1?.length !== array2?.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false;
    }
  }

  return true;
}
class AnalyticsUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moba: props.moba,
      certificates: null,
      number_of_diagnostics_by_team: null,
      number_of_diagnostics_by_dongle: null,
      number_of_diagnostics_by_user: null,
      number_of_diagnostics_by_site: null,
      number_of_diagnostics_by_delegator: null,
      number_of_diagnostics_by_delegation: null,
      startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
      endDate: new Date(),
      displayed: "team",
      sites_checked: null,
      countries_checked: null,
      loading: true,
    };

    this.handleShowTeam = () => {
      this.setState({ displayed: "team" });
    };
    this.handleShowOperator = () => {
      this.setState({ displayed: "operator" });
    };
    this.handleShowDongle = () => {
      this.setState({ displayed: "dongle" });
    };
    this.handleShowDelegator = () => {
      this.setState({ displayed: "delegator" });
    };
    this.handleShowDelegation = () => {
      this.setState({ displayed: "delegation" });
    };
    this.setSitesFiltered = (sites_checked) => {
      if (sites_checked) {
        this.setState({
          sites_checked: sites_checked,
        });
      } else {
        this.setState({
          sites_checked: null,
        });
      }
    };
    this.setCountriesFiltered = (countries_checked) => {
      if (countries_checked) {
        this.setState({
          countries_checked: countries_checked,
        });
      } else {
        this.setState({
          countries_checked: null,
        });
      }
    };
    this.onChange = (dates) => {
      if (!dates || dates.length < 2) {
        this.props.setState({
          startDate: null,
          endDate: null,
        });

        this.state.moba.filterCertificates({
          site: this.state.sites_checked,
          country: this.state.countries_checked,
        });
        this.updateStateBoxes();
        this.updateState();
      } else {
        const [start, end] = dates;
        this.props.setState({
          startDate: start,
          endDate: end,
        });
        if (start != null && end != null) {
          this.state.moba.filterCertificates({
            date: [start, end],
            site: this.state.sites_checked,
            country: this.state.countries_checked,
          });
          this.updateStateBoxes();
        }
        this.updateState();
      }
    };
    this.updateStateBoxes = async () => {
      this.setState({ loading: true }, () => {
        setTimeout(() => {
          this.state.moba.loadAllAnalyticsData();

          this.setState({
            certificates: this.state.moba.certificates,
            number_of_diagnostics_by_team:
              this.state.moba.number_of_diagnostics_by_team,
            number_of_diagnostics_by_dongle:
              this.state.moba.number_of_diagnostics_by_dongle,
            number_of_diagnostics_by_user:
              this.state.moba.number_of_diagnostics_by_user,
            number_of_diagnostics_by_site:
              this.state.moba.number_of_diagnostics_by_site,
            number_of_diagnostics_by_delegator:
              this.state.moba.number_of_diagnostics_by_delegator,
            number_of_diagnostics_by_delegation:
              this.state.moba.number_of_diagnostics_by_delegation,
            loading: false,
          });
        }, 0);
      });
    };

    this.chartType = () => {
      if (this.state.displayed === "team") {
        return "Team";
      } else if (this.state.displayed === "operator") {
        return "Operator";
      } else if (this.state.displayed === "dongle") {
        return "Dongle";
      } else if (this.state.displayed === "delegator") {
        return "Client";
      } else if (this.state.displayed === "delegation") {
        return "Société d'inspection";
      }
    }

    this.chartData = () => {
      if (this.state.displayed === "team") {
        return this.state.number_of_diagnostics_by_team;
      } else if (this.state.displayed === "operator") {
        return this.state.number_of_diagnostics_by_user;
      } else if (this.state.displayed === "dongle") {
        return this.state.number_of_diagnostics_by_dongle;
      } else if (this.state.displayed === "delegator") {
        return this.state.number_of_diagnostics_by_delegator;
      } else if (this.state.displayed === "delegation") {
        return this.state.number_of_diagnostics_by_delegation;
      }
    }

    this.updateState = () => {
      this.setState({
        certificates: this.state.moba.certificates,
        number_of_diagnostics_by_team:
          this.state.moba.number_of_diagnostics_by_team,
        number_of_diagnostics_by_dongle:
          this.state.moba.number_of_diagnostics_by_dongle,
        number_of_diagnostics_by_user:
          this.state.moba.number_of_diagnostics_by_user,
        number_of_diagnostics_by_site:
          this.state.moba.number_of_diagnostics_by_site,
        number_of_diagnostics_by_delegator:
          this.state.moba.number_of_diagnostics_by_delegator,
        number_of_diagnostics_by_delegation:
          this.state.moba.number_of_diagnostics_by_delegation,
          
      });
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !arraysAreEqual(prevState.sites_checked, this.state.sites_checked) ||
      !arraysAreEqual(prevState.countries_checked, this.state.countries_checked)
    ) {
      this.state.moba.filterCertificates({
        date: [this.state.startDate, this.state.endDate],
        site: this.state.sites_checked,
        country: this.state.countries_checked,
      });

      this.updateStateBoxes();

      this.updateState();
    }
    if (prevState.show !== this.props.show) {
      if (this.props.show === true && this.props.open === true) {
        this.props.setState({ open: false });
      }
    }
    if (prevState.open !== this.props.open) {
      if (this.props.open === true && this.props.show === true) {
        this.props.setState({ show: false });
      }
    }
  }

  componentDidMount() {
    mixpanel.track("User Opened Page", { page: "Analytics", subpage: "Teams" });
    this.updateStateBoxes();
  }

  componentWillUnmount() {
    this.state.moba.filterCertificates();
  }

  render() {
    return (
      <div className="my-4 h-full">
        <div className="flex flex-col h-full">
          <div className="flex">
            <FilterMoba
              filterOpened={false}
              setFilterOpened={() => null}
              column={{ moba: this.props.moba, filters: ["SITE", "COUNTRY"] }}
              setType={() => null}
              sitesFiltered={this.state.sites_checked}
              setSitesFiltered={this.setSitesFiltered}
              countriesFiltered={this.state.countries_checked}
              setCountriesFiltered={this.setCountriesFiltered}
            ></FilterMoba>
            <DatePicker
              opacity={this.props.opacity}
              days={this.props.days}
              setDays={this.props.setDays}
              show={this.props.show}
              setShow={this.props.setShow}
              startDate={this.props.startDate}
              endDate={this.props.endDate}
              toPrint={this.props.toPrint}
              setToPrint={this.props.setToPrint}
              onChange={this.onChange}
              lang={this.props.lang}
            />
          </div>
          <div className="mt-4 border-0.5 border-grey-200"></div>

          <div className="flex">
            <FilteredView
              lang={this.props.lang}
              itemsFiltered={this.state.sites_checked}
              setItemsFiltered={this.setSitesFiltered}
              filterType="SITES"
            />
            <FilteredView
              lang={this.props.lang}
              itemsFiltered={this.state.countries_checked}
              setItemsFiltered={this.setCountriesFiltered}
              filterType="COUNTRIES"
            />
          </div>
          <div className="flex mx-auto w-full mt-7 flex-1">
            <div className="flex flex-col p-4 w-2/3 border border-grey-200 shadow-sm bg-white rounded-md">
              <div className="mx-auto flex font-aeonik text-grey-500 items-center mt-3">
                <div className="mx-2">
                  <button
                    className={
                      this.state.displayed === "team"
                        ? "text-moba-green"
                        : "text-grey-500"
                    }
                    onClick={this.handleShowTeam}
                  >
                    {loc("Equipe", this.props.lang)}
                  </button>
                </div>
                <div className="mx-2">
                  <button
                    onClick={this.handleShowOperator}
                    className={
                      this.state.displayed === "operator"
                        ? "text-moba-green"
                        : "text-grey-500"
                    }
                  >
                    {loc("Opérateurs", this.props.lang)}
                  </button>
                </div>
                <div className="mx-2">
                  <button
                    onClick={this.handleShowDongle}
                    className={
                      this.state.displayed === "dongle"
                        ? "text-moba-green"
                        : "text-grey-500"
                    }
                  >
                    {loc("Boîtiers", this.props.lang)}
                  </button>
                </div>
                {
                  this.state.number_of_diagnostics_by_delegator && Object.keys(this.state.number_of_diagnostics_by_delegator).length > 0 && (
                    <div className="mx-2">
                      <button
                        onClick={this.handleShowDelegator}
                        className={
                          this.state.displayed === "delegator"
                            ? "text-moba-green"
                            : "text-grey-500"
                        }
                      >
                        {loc("Clients", this.props.lang)}
                      </button>
                    </div>
                  )
                }
                {
                  this.state.number_of_diagnostics_by_delegation && Object.keys(this.state.number_of_diagnostics_by_delegation).length > 0 && (
                    <div className="mx-2">
                      <button
                        onClick={this.handleShowDelegation}
                        className={
                          this.state.displayed === "delegation"
                            ? "text-moba-green"
                            : "text-grey-500"
                        }
                      >
                        {loc("Sociétés d'inspection", this.props.lang)}
                      </button>
                    </div>
                  )
                }
              </div>
              <div className="mt-3 mx-auto">
                <Divider />
              </div>
              <div className={"barchart team mt-3 flex-1"}>
                <BarChart
                  type={this.chartType()}
                  data={this.chartData()}
                  lang={this.props.lang}
                  loading={this.state.loading}
                ></BarChart>
              </div>
            </div>
            <div
              className="flex self-start flex-col w-1/3 border border-grey-200 shadow-sm bg-white rounded-md ml-3 p-4"
              style={{ minHeight: 250 }}
            >
              <p className="font-aeonik text-grey-800">
                {loc("Vos sites", this.props.lang)}
              </p>
              <div className="mt-10 mx-12 flex-1 flex justify-center">
                <PieChart
                  percent_pie={this.state.number_of_diagnostics_by_site}
                  type={"sites"}
                  lang={this.props.lang}
                  showLegend={true}
                  loading={this.state.loading}
                ></PieChart>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AnalyticsUser;

import React from "react";
import Header from "./Header";
import Pannel from "./Pannel";

export default function ProfilePage(props) {
	return (
		<div className="flex flex-row min-h-screen m-0">
			<Pannel lang={this.props.lang} />
			<div className="flex flex-col h-full min-h-screen">
				<Header connected={true} moba={props.moba} lang={props.lang} />
				<div className="h-full min-h-screen"></div>
			</div>
		</div>
	);
}


import { useState, useEffect, useRef, useMemo } from "react";

const Tooltip = ({ children, className, message, position, overflowOnly, disabled }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);
  const [targetRect, setTargetRect] = useState(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [messageLocal, setMessageLocal] = useState(message);
  const childrenRef = useRef(null);
  const tooltipRef = useRef(null);

  const MARGIN_CONTENT_TOOLTIP = 10;

  const arrowPosition = useMemo(() => {
    if (!position) {
      position = "bottom";
    }
    if (position === "top") {
      return {
        top: "100%",
        left: "50%",
        transform: "translateY(-50%) translateX(-50%) rotate(180deg)",
      };
    } else if (position === "bottom") {
      return {
        top: 0,
        left: "50%",
        transform: "translateY(-50%) translateX(-50%)",
      }; 
    } else if (position === "right") {
      return {
        top: "50%",
        left: 0,
        transform: "translateY(-50%) translateX(-50%) rotate(-90deg)",
      };
    } else if (position === "left") {
      return {
        top: "50%",
        left: "100%",
        transform: "translateY(-50%) translateX(-50%) rotate(90deg)",
      };
    }
  }, [position, isScrolling]);

  const tooltipPosition = useMemo(() => {
    if (targetRect === null) {
      return {
        top: 0,
        left: 0,
        transform: "translateY(-50%) translateX(-50%)",
      };
    }
    if (!position) {
      position = "bottom";
    }
    if (position === "top") {
      return {
        top: targetRect.top - MARGIN_CONTENT_TOOLTIP,
        left: targetRect.left + targetRect.width / 2,
        transform: "translateY(-100%) translateX(-50%)",
      };
      } else if (position === "bottom") {
        return {
          top: targetRect.top + targetRect.height + MARGIN_CONTENT_TOOLTIP,
          left: targetRect.left + targetRect.width / 2,
          transform: "translateY(0%) translateX(-50%)",
        };
      } else if (position === "right") {
        return {
          top: targetRect.top + targetRect.height / 2,
          left: targetRect.left + targetRect.width + MARGIN_CONTENT_TOOLTIP,
          transform: "translateY(-50%) translateX(0%)",
        };
      } else if (position === "left") {
        return {
          top: targetRect.top + targetRect.height / 2,
          left: targetRect.left - MARGIN_CONTENT_TOOLTIP,
          transform: "translateY(-50%) translateX(-100%)",
        };
      }
  }, [position, targetRect, isScrolling]);

  const findLeafChildren = (children) => {
    if (children?.children.length > 0) {
      return findLeafChildren(children.children[0]);
    }
    return children;
  }

  const getChildrenTextContent = (children) => {
    if (children?.textContent) {
      return children.textContent;
    }
    
    let textContent = "";

    if (children?.children) {
      for (let i = 0; i < children.children.length; i++) {
        textContent += getChildrenTextContent(children.children[i]);
      }
    }
    
    return textContent;
  }

  // Create a function named doesChildrenOverflow that check whether one of the children is overflowing the parent
  const doesChildrenOverflow = () => {
    if (!childrenRef.current) {
      return false;
    }
    const rootChild = childrenRef.current;
    const leafChild = findLeafChildren(rootChild);
    return leafChild.scrollWidth > rootChild.scrollWidth;
  };


  const onMouseEnterHandler = (event) => {
    setMouseOver(true);
    setTargetRect(event.target.getBoundingClientRect());
  };

  const onMouseLeaveHandler = () => {
    setMouseOver(false);
  };

  const onScrollHandler = () => {
    setShowTooltip(false);
    if (tooltipRef.current) {
      setIsScrolling(true);
    }
  }

  const onScrollEndHandler = () => {
    if (tooltipRef.current) {
      setTargetRect(childrenRef.current.getBoundingClientRect());
      setIsScrolling(false);
    }
  }

  useEffect(() => {
    let timeout;
    if (mouseOver && !isScrolling) {
      if (overflowOnly && !doesChildrenOverflow()) {
        return;
      }
      if (disabled) {
        return;
      }
      timeout = setTimeout(() => {
        if (message) {
          setMessageLocal(message);
        } else {
          setMessageLocal(getChildrenTextContent(childrenRef.current));
        }
        setShowTooltip(true);
      }, 500);
    } else {
      setShowTooltip(false);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [mouseOver, isScrolling]);

  useEffect(() => {
    document.addEventListener("scroll", onScrollHandler);
    document.addEventListener("scrollend", onScrollEndHandler);
    return () => {
      document.removeEventListener("scroll", onScrollHandler);
      document.removeEventListener("scrollend", onScrollEndHandler);
    };
  }, []);

  return (
    <div className={className} disabled={disabled}>
      <div
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        ref={ childrenRef }
      >
        {children}
      </div>
      {showTooltip && (
        <div
          className="fixed bg-grey-900 p-3 rounded-lg shadow-lg pointer-events-none max-w-xs flex z-50"
          style={{ top: tooltipPosition.top, left: tooltipPosition.left, transform: tooltipPosition.transform }}
          ref={ tooltipRef }
        >
          <p className="text-white font-aeonik whitespace-normal overflow-wrap">
            { messageLocal }
          </p>
          <div className="absolute self-center" style={{ top: arrowPosition.top, left: arrowPosition.left, transform: arrowPosition.transform }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path d="M1,21H23L12,2" fill="#101828"/></svg>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;

import { loc } from "../../helpers";
import {
  ArrowRightIcon,
  ArrowLeftIcon,
} from "./Icons";

export function Pagination(props) {
    const nbPages = props.pageOptions?.length || props.pageCount;
    const currentPage = props.state.pageIndex;
    const pageNeighbours = 1; 

    const isPageActive = (i) => {
        return i === currentPage;
    };

    const shouldDisplayPage = (i) => {
        if (i <= 2 || i >= nbPages - 3 || (i >= currentPage - pageNeighbours && i <= currentPage + pageNeighbours)) {
            return true;
        }
        return false;
    };
	
    const showEllipsis = (i) => {
        if (i === currentPage - pageNeighbours - 1) {
            return true;
        }
        if (i === currentPage + pageNeighbours + 1) {
            return true;
        }
		if(i === 3 && currentPage === 0) {
			return true;
		}
		if(i === nbPages - 4 && currentPage === nbPages - 1) {
			return true;
		}
        return false;
    };


	return (
		<div className="flex bg-white p-2 font-aeonik text-sm text-grey-500 w-full flex-1 items-end">
			<div className="mt-2 flex items-center w-full moba-table-pagination px-3">
				{
					nbPages > 1 &&
					<button
            className="flex items-center gap-2 text-grey-500 hover:text-branding-500"
						onClick={() => {
							props.previousPage();
						}}
					>
						<ArrowLeftIcon />
						<p>{loc("Précédent", props.lang)}</p>
					</button>
				}
				<div className="mx-auto pb-2 flex flex-1 justify-center">
					{
						//for 0 to nbPages
						[...Array(Math.max(nbPages, 0))].map((e, i) => {
							if (shouldDisplayPage(i)) {
								return (
									<button
										key={i}
										className={`flex items-center mx-2 p-2 w-8 h-8 rounded-full justify-center ${isPageActive(i) ? "bg-green-page cursor-default" : "hover:shadow"}`}
										onClick={() => {
											props.gotoPage(i);
										}}
										disabled={isPageActive(i)}
									>
										<p className={`font-bold ${isPageActive(i) ? "text-branding-500 px-1" : ""}`}>
											{i + 1}
										</p>
									</button>
								);
							}  else if (showEllipsis(i)) {
								return (
									<div key={i} className="flex items-center mx-2">
										<p className="">...</p>
									</div>
								);
							}
							return null;
						})
					}
				</div>
				{
					nbPages > 1 &&
					<button
						className="flex items-center gap-2 text-grey-500 hover:text-branding-500"
						onClick={() => {
							props.nextPage();
						}}
					>
            <p>{loc("Suivant", props.lang)}</p>
						<ArrowRightIcon />
					</button>
				}
			</div>
		</div>
	);
}

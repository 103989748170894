import React from "react";
import { Modal, Button } from "react-bootstrap";
import { loc } from "../../helpers";

const ConfirmationModal = ({
  show,
  title,
  message,
  onConfirm,
  onCancel,
  confirmLabel,
  cancelLabel,
  lang,
  confirmDisabled
}) => {
  return (
    <Modal className="modal center-modal" show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <button
            className="flex p-1 flex-1 h-12 border border-1 rounded-md justify-center mx-3"
            variant="primary"
            type="button"
            onClick={onCancel}
        >
            <p className="m-auto font-aeonik">{cancelLabel || loc("Annuler", lang)}</p>
        </button>
        {onConfirm && (
            <button
                className={`flex p-1 flex-1 h-12 border border-1 rounded-md justify-center mx-3 bg-branding-500 ${confirmDisabled && "opacity-50 cursor-default"}`}
                variant="primary"
                type="submit"
                disabled={confirmDisabled}
                onClick={onConfirm}
            >
                <p className="m-auto text-white font-aeonik">{confirmLabel || loc("Confirmer", lang)}</p>
            </button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;

import { loc } from "../../helpers";

const FooterLogin = ({ lang }) => {

    const sendMail = () => {
        window.location.href = "mailto:support@get-moba.com";
    }

    const privacyRedirect = () => {
        window.open("https://get-moba.com/en/personal-data-gdpr/", "_blank");
    }


    return (
        <div className="flex mt-auto left-0 right-0 bottom-0 justify-center py-6">
        <button className="mx-4 underline" onClick={privacyRedirect}>
                { loc("Politique de confidentialité", lang) }
            </button>
            <button className="mx-4 underline" onClick={sendMail}>
                { loc("Nous contacter", lang) }
            </button>
        </div>
    )
}

export default FooterLogin
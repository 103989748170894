import React, { useEffect, useRef, useMemo } from "react";
import { loc, removeDuplicates } from "../../helpers";
import {
  CheckBoxBaseIcon,
  CheckBoxCheckedIcon,
  CheckBoxPartialCheckedIcon,
  DiagnosticIcon,
  SiteIcon,
  TeamIcon,
  CountryIcon,
  RoleIcon,
  DelegationIcon,
  FilterIcon
} from "./Icons";

const FILTERS = {
  DIAG_COST: "DIAG_COST",
  SITE: "SITE",
  TEAM: "TEAM",
  COUNTRY: "COUNTRY",
  ROLE: "ROLE",
  DELEGATOR: "DELEGATOR",
  DELEGATION: "DELEGATION",
};

export function FilterMoba({
  data,
  column: { setFilter, lang, moba, filters, filterFromTableValues },
  filterOpened,
  setFilterOpened,
  
  sitesFiltered,
  teamsFiltered,
  diagCostFiltered,
  countriesFiltered,
  rolesFiltered,
  delegatorsFiltered,
  delegationsFiltered,

  setSitesFiltered,
  setTeamsFiltered,
  setRolesFiltered,
  setCountriesFiltered,
  setDiagCostFiltered,
  setDelegatorsFiltered,
  setDelegationsFiltered,
}) {
  const [open, setOpen] = React.useState(false);

  if (filterFromTableValues === undefined) {
    filterFromTableValues = true;
  }

  if (!filters) {
    filters = [];
  }

  const addEmptyElement = (array, key = "id") => {
    if (array) {
      array.unshift({ [key]: null, name: loc("Non défini", lang) });
    }
  };


  const filterActive =
    sitesFiltered?.length ||
    diagCostFiltered?.length ||
    teamsFiltered?.length ||
    countriesFiltered?.length ||
    rolesFiltered?.length ||
    delegatorsFiltered?.length ||
    delegationsFiltered?.length;


  useEffect(() => {
    const handleClickOutside = (event) => {
      //if class auth-modal has not the class hidden and the user clicks outside the modal, the modal closes
      if (!event.target.closest(".auth-modal")) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (filterOpened === "date") setOpen(false);
  }, [filterOpened]);

  const isFilterActive = (filter) => {
    if (!filters.includes(filter)) {
      return false;
    }

    if (filter === FILTERS.DELEGATOR && filterableDelegators?.length <= 1) {
      return false;
    }

    if (filter === FILTERS.DELEGATION && filterableDelegations?.length <= 1) {
      return false;
    }

    return true;
  }


  const getTopPixels = (filter) => {
    const availableFilters = Object.values(FILTERS);
    const activeFilters = availableFilters.filter((f) => isFilterActive(f));
    const filterIndex = activeFilters.indexOf(filter);
    const topInRem = filterIndex * 2.75; // 2.75 rem
    return `${topInRem}rem`;
  };

  // Sites

  const [filterableSites, setFilterableSites] = React.useState(null);
  const [siteHover, setSiteHover] = React.useState(false);
  const [siteEnterHover, setSiteEnterHover] = React.useState(false);
  const hoverSiteTimeoutRef = useRef(null);
  const [sites_checked, setSitesChecked] = React.useState(filterableSites);

  const handleMouseEnterSite = () => {
    clearTimeout(hoverSiteTimeoutRef.current);
    setDiagCostHover(false);
    setTeamHover(false);
    setSiteHover(true);
    setCountryHover(false);
    setRoleHover(false);
    setDelegatorHover(false);
    setDelegationHover(false);
  };

  const handleMouseLeaveSite = () => {
    hoverSiteTimeoutRef.current = setTimeout(() => {
      setSiteHover(false);
    }, 500); // delay of 500ms
  };

  const isSiteChecked = (site) => {
    return sites_checked.some((s) => s.id === site.id);
  };

  const checkAllSites = () => {
    setSitesChecked(filterableSites);
    setSitesFiltered(null);
  };

  const uncheckAllSites = () => {
    setSitesChecked([]);
    setSitesFiltered([]);
  };

  const checkSite = (site) => {
    const newSitesChecked = [...sites_checked, site];
    setSitesChecked(newSitesChecked);
    setSitesFiltered(
      newSitesChecked.length < filterableSites.length ? newSitesChecked : null
    );
  };

  const uncheckSite = (site) => {
    const newSitesChecked = sites_checked.filter((s) => s.id !== site.id);
    setSitesChecked(newSitesChecked);
    setSitesFiltered(newSitesChecked);
  };

  const toggleSite = (site) => {
    if (isSiteChecked(site)) {
      uncheckSite(site);
    } else {
      checkSite(site);
    }
  };

  const siteSelectionState = useMemo(() => {
    if (sites_checked?.length === 0) return "none";
    if (sites_checked?.length === filterableSites?.length) return "all";
    return "some";
  }, [sites_checked, filterableSites]);

  const siteBulkActionIcon = useMemo(() => {
    if (siteSelectionState === "none") return <CheckBoxBaseIcon />;
    if (siteSelectionState === "all") return <CheckBoxCheckedIcon />;
    return <CheckBoxPartialCheckedIcon />;
  }, [siteSelectionState]);

  const siteBulkActionClickHandler = () => {
    if (siteSelectionState === "none") {
      checkAllSites();
    } else {
      uncheckAllSites();
    }
  };

  if (filters.includes(FILTERS.SITE)) {

    useEffect(() => {
      let filterablesSitesTemp;
      const hasAnySite = data?.some((row) => !!row.site);
      if (hasAnySite && filterFromTableValues) {

        filterablesSitesTemp = removeDuplicates(
          data.map((row, i) => {
            return row.site;
          })
        );
      } else {
        filterablesSitesTemp = moba.getFilterableSites();
      }
      addEmptyElement(filterablesSitesTemp);
      setFilterableSites(filterablesSitesTemp);
      checkAllSites();
    }, [])

    useEffect(() => {
      checkAllSites();
    }, [filterableSites])

    useEffect(() => {
      if (sitesFiltered === null) {
        setSitesChecked(filterableSites);
      } else {
        setSitesChecked(sitesFiltered);
      }
    }, [sitesFiltered]);
  }


  // Diag costs

  let filterableDiagCosts = [
    {
      label: loc("Diagnostics facturés", lang),
      id: 1,
    },
    {
      label: loc("Diagnostics non facturés", lang),
      id: 0,
    },
  ];

  const [diagCostHover, setDiagCostHover] = React.useState(false);
  const [diagCostEnterHover, setDiagCostEnterHover] = React.useState(false);
  const hoverDiagCostTimeoutRef = useRef(null);
  const [diag_cost_checked, setDiagCostChecked] = React.useState(filterableDiagCosts);

  const handleMouseEnterDiagCost = () => {
    clearTimeout(hoverDiagCostTimeoutRef.current);
    setTeamHover(false);
    setSiteHover(false);
    setDiagCostHover(true);
    setCountryHover(false);
    setRoleHover(false);
    setDelegatorHover(false);
    setDelegationHover(false);
  };

  const handleMouseLeaveDiagCost = () => {
    hoverDiagCostTimeoutRef.current = setTimeout(() => {
      setDiagCostHover(false);
    }, 500); // delay of 500ms
  };

  const isDiagCostChecked = (diag_cost) => {
    return diag_cost_checked.some((d) => d.id === diag_cost.id);
  };

  const checkAllDiagCosts = () => {
    setDiagCostChecked(filterableDiagCosts);
    setDiagCostFiltered(null);
  };

  const uncheckAllDiagCosts = () => {
    setDiagCostChecked([]);
    setDiagCostFiltered([]);
  };

  const checkDiagCost = (diag_cost) => {
    const newDiagCostChecked = [...diag_cost_checked, diag_cost];
    setDiagCostChecked(newDiagCostChecked);
    setDiagCostFiltered(
      newDiagCostChecked.length < filterableDiagCosts.length
        ? newDiagCostChecked
        : null
    );
  };

  const uncheckDiagCost = (diag_cost) => {
    const newDiagCostChecked = diag_cost_checked.filter(
      (d) => d.id !== diag_cost.id
    );
    setDiagCostChecked(newDiagCostChecked);
    setDiagCostFiltered(newDiagCostChecked);
  };

  const toggleDiagCost = (diag_cost) => {
    if (isDiagCostChecked(diag_cost)) {
      uncheckDiagCost(diag_cost);
    } else {
      checkDiagCost(diag_cost);
    }
  };

  const diagCostSelectionState = useMemo(() => {
    if (diag_cost_checked.length === 0) return "none";
    if (diag_cost_checked.length === filterableDiagCosts.length) return "all";
    return "some";
  }, [diag_cost_checked.length, filterableDiagCosts.length]);

  const diagCostBulkActionIcon = useMemo(() => {
    if (diagCostSelectionState === "none") return <CheckBoxBaseIcon />;
    if (diagCostSelectionState === "all") return <CheckBoxCheckedIcon />;
    return <CheckBoxPartialCheckedIcon />;
  }, [diagCostSelectionState]);

  const diagCostBulkActionClickHandler = () => {
    if (diagCostSelectionState === "none") {
      checkAllDiagCosts();
    } else {
      uncheckAllDiagCosts();
    }
  };

  if (filters.includes(FILTERS.DIAG_COST)) {

    useEffect(() => {
      if (diagCostFiltered === null) {
        setDiagCostChecked(filterableDiagCosts);
      } else {
        setDiagCostChecked(diagCostFiltered);
      }
    }, [diagCostFiltered]);
  }


  // Teams

  const [filterableTeams, setFilterableTeams] = React.useState(null);
  const [teamHover, setTeamHover] = React.useState(false);
  const [teamEnterHover, setTeamEnterHover] = React.useState(false);
  const [teams_checked, setTeamsChecked] = React.useState(filterableTeams);
  const hoverTeamTimeoutRef = useRef(null);

  const handleMouseEnterTeam = () => {
    clearTimeout(hoverTeamTimeoutRef.current);
    setSiteHover(false);
    setDiagCostHover(false);
    setTeamHover(true);
    setCountryHover(false);
    setRoleHover(false);
    setDelegatorHover(false);
    setDelegationHover(false);
  };

  const handleMouseLeaveTeam = () => {
    hoverTeamTimeoutRef.current = setTimeout(() => {
      setTeamHover(false);
    }, 500); // delay of 500ms
  };

  const isTeamChecked = (team) => {
    return teams_checked.some((t) => t.id === team.id);
  };

  const checkAllTeams = () => {
    setTeamsChecked(filterableTeams);
    setTeamsFiltered(null);
  };

  const uncheckAllTeams = () => {
    setTeamsChecked([]);
    setTeamsFiltered([]);
  };

  const checkTeam = (team) => {
    const newSitesChecked = [...teams_checked, team];
    setTeamsChecked(newSitesChecked);
    setTeamsFiltered(
      newSitesChecked.length < filterableTeams.length ? newSitesChecked : null
    );
  };

  const uncheckTeam = (team) => {
    const newTeamsChecked = teams_checked.filter((t) => t.id !== team.id);
    setTeamsChecked(newTeamsChecked);
    setTeamsFiltered(newTeamsChecked);
  };

  const toggleTeam = (team) => {
    if (isTeamChecked(team)) {
      uncheckTeam(team);
    } else {
      checkTeam(team);
    }
  };

  const teamSelectionState = useMemo(() => {
    if (teams_checked?.length === 0) return "none";
    if (teams_checked?.length === filterableTeams?.length) return "all";
    return "some";
  }, [teams_checked, filterableTeams]);

  const teamBulkActionIcon = useMemo(() => {
    if (teamSelectionState === "none") return <CheckBoxBaseIcon />;
    if (teamSelectionState === "all") return <CheckBoxCheckedIcon />;
    return <CheckBoxPartialCheckedIcon />;
  }, [teamSelectionState]);

  const teamBulkActionClickHandler = () => {
    if (teamSelectionState === "none") {
      checkAllTeams();
    } else {
      uncheckAllTeams();
    }
  };

  if (filters.includes(FILTERS.TEAM)) {

    useEffect(() => {
      let filterableTeamsTemp;
      const hasAnyTeam = data?.some((row) => !!row.team);

      if (hasAnyTeam && filterFromTableValues) {
        filterableTeamsTemp = removeDuplicates(
          data.map((row, i) => {
            return row.team;
          })
        );
      } else {
        filterableTeamsTemp = moba.getFilterableTeams();
      }
      addEmptyElement(filterableTeamsTemp);
      setFilterableTeams(filterableTeamsTemp);
      checkAllTeams();
    }, [])

    useEffect(() => {
      checkAllTeams();
    }, [filterableTeams])

    useEffect(() => {
      if (teamsFiltered === null) {
        setTeamsChecked(filterableTeams);
      } else {
        setTeamsChecked(teamsFiltered);
      }
    }, [teamsFiltered]);
  }
  

  // Countries

  const [filterableCountries, setFilterableCountries] = React.useState(null);
  const [countryHover, setCountryHover] = React.useState(false);
  const [countryEnterHover, setCountryEnterHover] = React.useState(false);
  const hoverCountryTimeoutRef = useRef(null);
  const [countries_checked, setCountriesChecked] = React.useState(filterableCountries);

  const handleMouseEnterCountry = () => {
    clearTimeout(hoverCountryTimeoutRef.current);
    setCountryHover(true);
    setSiteHover(false);
    setDiagCostHover(false);
    setTeamHover(false);
    setRoleHover(false);
    setDelegatorHover(false);
    setDelegationHover(false);
  };

  const handleMouseLeaveCountry = () => {
    hoverCountryTimeoutRef.current = setTimeout(() => {
      setCountryHover(false);
    }, 500); // delay of 500ms
  };const isCountryChecked = (country) => {
    return countries_checked.some((c) => c.id === country.id);
  };

  const checkAllCountries = () => {
    setCountriesChecked(filterableCountries);
    setCountriesFiltered(null);
  };

  const uncheckAllCountries = () => {
    setCountriesChecked([]);
    setCountriesFiltered([]);
  };

  const checkCountry = (country) => {
    const newCountriesChecked = [...countries_checked, country];
    setCountriesChecked(newCountriesChecked);
    setCountriesFiltered(
      newCountriesChecked.length < filterableCountries.length
        ? newCountriesChecked
        : null
    );
  };

  const uncheckCountry = (country) => {
    const newCountriesChecked = countries_checked.filter(
      (c) => c.id !== country.id
    );
    setCountriesChecked(newCountriesChecked);
    setCountriesFiltered(newCountriesChecked);
  };

  const toggleCountry = (country) => {
    if (isCountryChecked(country)) {
      uncheckCountry(country);
    } else {
      checkCountry(country);
    }
  };

  const countrySelectionState = useMemo(() => {
    if (countries_checked?.length === 0) return "none";
    if (countries_checked?.length === filterableCountries?.length) return "all";
    return "some";
  }, [countries_checked, filterableCountries]);

  const countryBulkActionIcon = useMemo(() => {
    if (countrySelectionState === "none") return <CheckBoxBaseIcon />;
    if (countrySelectionState === "all") return <CheckBoxCheckedIcon />;
    return <CheckBoxPartialCheckedIcon />;
  }, [countrySelectionState]);

  const countryBulkActionClickHandler = () => {
    if (countrySelectionState === "none") {
      checkAllCountries();
    } else {
      uncheckAllCountries();
    }
  };

  if (filters.includes(FILTERS.COUNTRY)) {

    useEffect(() => {
      let filterableCountriesTemp;
      const hasAnyCountry = data?.some((row) => !!row.country);

      if (hasAnyCountry && filterFromTableValues) {
        filterableCountriesTemp = removeDuplicates(
          data.map((row, i) => {
            return moba.getCountryFromCode(row.country);
          })
        );
      } else {
        filterableCountriesTemp = moba.getFilterableCountries();
      }
      addEmptyElement(filterableCountriesTemp);
      setFilterableCountries(filterableCountriesTemp);
      checkAllCountries();
    }, [])
  
    useEffect(() => {
      const filterableCountries = moba.getFilterableCountries();
      addEmptyElement(filterableCountries);
      setFilterableCountries(filterableCountries);
    }, [moba.available_countries])

    useEffect(() => {
      checkAllCountries();
    }, [filterableCountries])

    useEffect(() => {
      if (countriesFiltered === null) {
        setCountriesChecked(filterableCountries);
      } else {
        setCountriesChecked(countriesFiltered);
      }
    }, [countriesFiltered]);
  }

  // Roles

  const [filterableRoles, setFilterableRoles] = React.useState(null);
  const [roleHover, setRoleHover] = React.useState(false);
  const [roleEnterHover, setRoleEnterHover] = React.useState(false);
  const hoverRoleTimeoutRef = useRef(null);
  const [roles_checked, setRolesChecked] = React.useState(filterableRoles);

  const handleMouseEnterRole = () => {
    clearTimeout(hoverRoleTimeoutRef.current);
    setRoleHover(true);
    setSiteHover(false);
    setDiagCostHover(false);
    setTeamHover(false);
    setCountryHover(false);
    setDelegatorHover(false);
    setDelegationHover(false);
  };

  const handleMouseLeaveRole = () => {
    hoverRoleTimeoutRef.current = setTimeout(() => {
      setRoleHover(false);
    }, 500); // delay of 500ms
  };

  const isRoleChecked = (role) => {
    return roles_checked.includes(role);
  };

  const checkAllRoles = () => {
    setRolesChecked(filterableRoles);
    setRolesFiltered(null);
  };

  const uncheckAllRoles = () => {
    setRolesChecked([]);
    setRolesFiltered([]);
  };

  const checkRole = (role) => {
    const newRolesChecked = [...roles_checked, role];
    setRolesChecked(newRolesChecked);
    setRolesFiltered(
      newRolesChecked.length < filterableRoles.length ? newRolesChecked : null
    );
  };

  const uncheckRole = (role) => {
    const newRolesChecked = roles_checked.filter((r) => r.id !== role.id);
    setRolesChecked(newRolesChecked);
    setRolesFiltered(newRolesChecked);
  };

  const toggleRole = (role) => {
    if (isRoleChecked(role)) {
      uncheckRole(role);
    } else {
      checkRole(role);
    }
  };

  const roleSelectionState = useMemo(() => {
    if (roles_checked?.length === 0) return "none";
    if (roles_checked?.length === filterableRoles?.length) return "all";
    return "some";
  }, [roles_checked?.length, filterableRoles?.length]);

  const roleBulkActionIcon = useMemo(() => {
    if (roleSelectionState === "none") return <CheckBoxBaseIcon />;
    if (roleSelectionState === "all") return <CheckBoxCheckedIcon />;
    return <CheckBoxPartialCheckedIcon />;
  }, [roleSelectionState]);

  const roleBulkActionClickHandler = () => {
    if (roleSelectionState === "none") {
      checkAllRoles();
    } else {
      uncheckAllRoles();
    }
  };

  if (filters.includes(FILTERS.ROLE)) {

    useEffect(() => {
      let filterableRolesTemp;
      const hasAnyRole = data?.some((row) => !!row.role);
      if (hasAnyRole) {
        filterableRolesTemp = removeDuplicates(
          data.map((row, i) => {
            return row.role;
          })
        );
      } else {
        filterableRolesTemp = moba.getFilterableRoles();
      }
      addEmptyElement(filterableRolesTemp);
      setFilterableRoles(filterableRolesTemp);
      checkAllRoles();
    }, [])

    useEffect(() => {
      checkAllRoles();
    }, [filterableRoles])

    useEffect(() => {
      if (rolesFiltered === null) {
        setRolesChecked(filterableRoles);
      } else {
        setRolesChecked(rolesFiltered);
      }
    }, [rolesFiltered]);
  }

  // Delegators

  const [filterableDelegators, setFilterableDelegators] = React.useState(null);
  const [delegatorHover, setDelegatorHover] = React.useState(false);
  const [delegatorEnterHover, setDelegatorEnterHover] = React.useState(false);
  const hoverDelegatorTimeoutRef = useRef(null);
  const [delegators_checked, setDelegatorsChecked] = React.useState(filterableDelegators);

  const handleMouseEnterDelegator = () => {
    clearTimeout(hoverDelegatorTimeoutRef.current);
    setDelegatorHover(true);
    setSiteHover(false);
    setDiagCostHover(false);
    setTeamHover(false);
    setCountryHover(false);
    setDelegationHover(false);
  };

  const handleMouseLeaveDelegator = () => {
    hoverDelegatorTimeoutRef.current = setTimeout(() => {
      setDelegatorHover(false);
    }, 500); // delay of 500ms
  };

  const isDelegatorChecked = (delegator) => {
    return delegators_checked.includes(delegator);
  };

  const checkAllDelegators = () => {
    setDelegatorsChecked(filterableDelegators);
    setDelegatorsFiltered(null);
  };

  const uncheckAllDelegators = () => {
    setDelegatorsChecked([]);
    setDelegatorsFiltered([]);
  };

  const checkDelegator = (delegator) => {
    const newDelegatorsChecked = [...delegators_checked, delegator];
    setDelegatorsChecked(newDelegatorsChecked);
    setDelegatorsFiltered(
      newDelegatorsChecked.length < filterableDelegators.length ? newDelegatorsChecked : null
    );
  };

  const uncheckDelegator = (delegator) => {
    const newDelegatorsChecked = delegators_checked.filter((d) => d.id !== delegator.id);
    setDelegatorsChecked(newDelegatorsChecked);
    setDelegatorsFiltered(newDelegatorsChecked);
  };

  const toggleDelegator = (delegator) => {
    if (isDelegatorChecked(delegator)) {
      uncheckDelegator(delegator);
    } else {
      checkDelegator(delegator);
    }
  };

  const delegatorSelectionState = useMemo(() => {
    if (delegators_checked?.length === 0) return "none";
    if (delegators_checked?.length === filterableDelegators?.length) return "all";
    return "some";
  }, [delegators_checked?.length, filterableDelegators?.length]);

  const delegatorBulkActionIcon = useMemo(() => {
    if (delegatorSelectionState === "none") return <CheckBoxBaseIcon />;
    if (delegatorSelectionState === "all") return <CheckBoxCheckedIcon />;
    return <CheckBoxPartialCheckedIcon />;
  }, [delegatorSelectionState]);

  const delegatorBulkActionClickHandler = () => {
    if (delegatorSelectionState === "none") {
      checkAllDelegators();
    } else {
      uncheckAllDelegators();
    }
  };

  if (filters.includes(FILTERS.DELEGATOR)) {

    useEffect(() => {
      let filterableDelegatorsTemp;
      const hasAnyDelegator = data?.some((row) => !!row.delegator);

      if (hasAnyDelegator) {
        filterableDelegatorsTemp = removeDuplicates(
          data.map((row, i) => {
            return row.delegator?.entity;
          }).filter((delegator) => !!delegator)
        );
      } else {
        filterableDelegatorsTemp = moba.getFilterableDelegators();
      }
      addEmptyElement(filterableDelegatorsTemp);
      setFilterableDelegators(filterableDelegatorsTemp);
      checkAllDelegators();
    }, [])

    useEffect(() => {
      checkAllDelegators();
    }, [filterableDelegators])

    useEffect(() => {
      if (delegatorsFiltered === null) {
        setDelegatorsChecked(filterableDelegators);
      } else {
        setDelegatorsChecked(delegatorsFiltered);
      }
    }, [delegatorsFiltered]);
  }

  // Delegations

  const [filterableDelegations, setFilterableDelegations] = React.useState(null);
  const [delegationHover, setDelegationHover] = React.useState(false);
  const [delegationEnterHover, setDelegationEnterHover] = React.useState(false);
  const hoverDelegationTimeoutRef = useRef(null);
  const [delegations_checked, setDelegationsChecked] = React.useState(filterableDelegations);

  const handleMouseEnterDelegation = () => {
    clearTimeout(hoverDelegationTimeoutRef.current);
    setDelegationHover(true);
    setSiteHover(false);
    setDiagCostHover(false);
    setTeamHover(false);
    setCountryHover(false);
    setDelegatorHover(false);
  };

  const handleMouseLeaveDelegation = () => {
    hoverDelegationTimeoutRef.current = setTimeout(() => {
      setDelegationHover(false);
    }, 500); // delay of 500ms
  };

  const isDelegationChecked = (delegation) => {
    return delegations_checked.includes(delegation);
  };

  const checkAllDelegations = () => {
    setDelegationsChecked(filterableDelegations);
    setDelegationsFiltered(null);
  };

  const uncheckAllDelegations = () => {
    setDelegationsChecked([]);
    setDelegationsFiltered([]);
  };

  const checkDelegation = (delegation) => {
    const newDelegationsChecked = [...delegations_checked, delegation];
    setDelegationsChecked(newDelegationsChecked);
    setDelegationsFiltered(
      newDelegationsChecked.length < filterableDelegations.length ? newDelegationsChecked : null
    );
  };

  const uncheckDelegation = (delegation) => {
    const newDelegationsChecked = delegations_checked.filter((r) => r.id !== delegation.id);
    setDelegationsChecked(newDelegationsChecked);
    setDelegationsFiltered(newDelegationsChecked);
  };

  const toggleDelegation = (delegation) => {
    if (isDelegationChecked(delegation)) {
      uncheckDelegation(delegation);
    } else {
      checkDelegation(delegation);
    }
  };

  const delegationSelectionState = useMemo(() => {
    if (delegations_checked?.length === 0) return "none";
    if (delegations_checked?.length === filterableDelegations?.length) return "all";
    return "some";
  }, [delegations_checked?.length, filterableDelegations?.length]);

  const delegationBulkActionIcon = useMemo(() => {
    if (delegationSelectionState === "none") return <CheckBoxBaseIcon />;
    if (delegationSelectionState === "all") return <CheckBoxCheckedIcon />;
    return <CheckBoxPartialCheckedIcon />;
  }, [delegationSelectionState]);

  const delegationBulkActionClickHandler = () => {
    if (delegationSelectionState === "none") {
      checkAllDelegations();
    } else {
      uncheckAllDelegations();
    }
  };

  if (filters.includes(FILTERS.DELEGATION)) {

    useEffect(() => {
      let filterableDelegationsTemp;
      const hasAnyDelegation = data?.some((row) => !!row.delegation);
      if (hasAnyDelegation) {
        filterableDelegationsTemp = removeDuplicates(
          data.map((row, i) => {
            return row.delegation?.entity;
          }).filter((delegation) => !!delegation)
        );
      } else {
        filterableDelegationsTemp = moba.getFilterableDelegations();
      }
      addEmptyElement(filterableDelegationsTemp);
      setFilterableDelegations(filterableDelegationsTemp);
      checkAllDelegations();
    }, [])

    useEffect(() => {
      checkAllDelegations();
    }, [filterableDelegations])

    useEffect(() => {
      if (delegationsFiltered === null) {
        setDelegationsChecked(filterableDelegations);
      } else {
        setDelegationsChecked(delegationsFiltered);
      }
    }, [delegationsFiltered]);
  }


  useEffect(() => {
    if (setFilter) {
      setFilter({
        site: (filters.includes(FILTERS.SITE) ? sites_checked : null),
        team: (filters.includes(FILTERS.TEAM) ? teams_checked : null),
        country: (filters.includes(FILTERS.COUNTRY) ? countries_checked : null),
        role: (filters.includes(FILTERS.ROLE) ? roles_checked : null),
        cost: (filters.includes(FILTERS.DIAG_COST) ? diag_cost_checked : null),
        'delegator.entity': (filters.includes(FILTERS.DELEGATOR) ? delegatorsFiltered : null),
        'delegation.entity': (filters.includes(FILTERS.DELEGATION) ? delegationsFiltered : null),
      });
    }
  }, [
    sites_checked,
    teams_checked,
    countries_checked,
    roles_checked,
    diag_cost_checked,
    delegators_checked,
    delegations_checked,
  ]);

  return (
    <div
      className={`relative flex flex-col auth-modal`}
    >
      <button
        className={`flex items-center border-1 hover:border-branding-500 hover:text-branding-500 text-grey-500 ${
          filterActive ? "border-branding-500" : " border-grey-300"
        } h-11 w-24 justify-center items-center rounded-md p-2`}
        style={{ minWidth: 75 }}
        onClick={() => {
          if (!open) setFilterOpened("filter");
          else setFilterOpened("");
          setOpen(!open);
        }}
      >
        <p className={`flex justify-around items-center w-100 ${filterActive ? "text-branding-500" : ""}`}>
          {loc("Filtrer", lang)}
          <FilterIcon className="w-5"/>
        </p>
      </button>
      {open && (
        <div className="absolute z-50 top-10 my-2 flex flex-col bg-white shadow rounded-md w-80">
          {isFilterActive(FILTERS.DIAG_COST) && (
            <div
              className="flex h-11 text-base items-center cursor-pointer hover:bg-branding-25 hover:text-branding-500"
              onMouseEnter={handleMouseEnterDiagCost}
              onMouseLeave={handleMouseLeaveDiagCost}
            >
              <div className="ml-2">
                <DiagnosticIcon />
              </div>
              <p className="my-auto text-base font-aeonik ml-3">
                {loc("Diagnostics", lang)}
              </p>
            </div>
          )}
          {isFilterActive(FILTERS.SITE) && (
            <div
              className="flex h-11 text-base items-center cursor-pointer hover:bg-branding-25 hover:text-branding-500"
              onMouseEnter={handleMouseEnterSite}
              onMouseLeave={handleMouseLeaveSite}
            >
              <div className="ml-2">
                <SiteIcon/>
              </div>
              <p className="my-auto font-aeonik ml-3">
                {loc("Site", lang)}
              </p>
            </div>
          )}
          {isFilterActive(FILTERS.TEAM) && (
            <div
              className={
                "flex h-11 text-base items-center cursor-pointer hover:bg-branding-25 hover:text-branding-500"
              }
              onMouseEnter={handleMouseEnterTeam}
              onMouseLeave={handleMouseLeaveTeam}
            >
              <div className="ml-2">
                <TeamIcon />
              </div>
              <p className="my-auto font-aeonik ml-3">
                {loc("Équipes", lang)}
              </p>
            </div>
          )}
          {isFilterActive(FILTERS.COUNTRY) && (
            <div
              className="flex h-11 text-base items-center cursor-pointer hover:bg-branding-25 hover:text-branding-500"
              onMouseEnter={handleMouseEnterCountry}
              onMouseLeave={handleMouseLeaveCountry}
            >
              <div className="ml-2">
                <CountryIcon />
              </div>
              <p className="my-auto text-base font-aeonik ml-3">
                {loc("Pays", lang)}
              </p>
            </div>
          )}
          {isFilterActive(FILTERS.ROLE) && (
            <div
              className="flex h-11 text-base items-center cursor-pointer hover:bg-branding-25 hover:text-branding-500"
              onMouseEnter={handleMouseEnterRole}
              onMouseLeave={handleMouseLeaveRole}
            >
              <div className="ml-2">
                <RoleIcon />
              </div>
              <p className="my-auto text-base font-aeonik ml-3">
                {loc("Rôle", lang)}
              </p>
            </div>
          )}
          {isFilterActive(FILTERS.DELEGATOR) && (
            <div
              className="flex h-11 text-base items-center cursor-pointer hover:bg-branding-25 hover:text-branding-500"
              onMouseEnter={handleMouseEnterDelegator}
              onMouseLeave={handleMouseLeaveDelegator}
            >
              <div className="ml-2">
                <DiagnosticIcon />
              </div>
              <p className="my-auto text-base font-aeonik ml-3">
                {loc("Client", lang)}
              </p>
            </div>
          )}
          {isFilterActive(FILTERS.DELEGATION) && (
            <div
              className="flex h-11 text-base items-center cursor-pointer hover:bg-branding-25 hover:text-branding-500"
              onMouseEnter={handleMouseEnterDelegation}
              onMouseLeave={handleMouseLeaveDelegation}
            >
              <div className="ml-2">
                <DelegationIcon />
              </div>
              <p className="my-auto text-base font-aeonik ml-3">
                {loc("Société d'inspection", lang)}
              </p>
            </div>
          )}
          {(diagCostHover || diagCostEnterHover) && (
            <div
              className="absolute left-80 flex flex-col bg-white shadow rounded-md w-80 mx-2 max-h-110 overflow-y-auto"
              onMouseEnter={handleMouseEnterDiagCost}
              onMouseLeave={handleMouseLeaveDiagCost}
              style={{ top: getTopPixels(FILTERS.DIAG_COST)}}
            >
              <button
                className="flex my-2.5 items-center font-bold"
                onClick={diagCostBulkActionClickHandler}
              >
                <div className="ml-2 pointer-events-none">
                  {diagCostBulkActionIcon}
                </div>
                <p className="ml-3">{loc("Diagnostics", lang)}</p>
              </button>
              <hr className="mx-auto w-10/12"></hr>

              {filterableDiagCosts.map((diag_cost) => {
                return (
                  <button
                    className="flex my-2.5 items-center"
                    onClick={() => toggleDiagCost(diag_cost)}
                    key={diag_cost.id}
                  >
                    <div className="ml-2 pointer-events-none">
                      {!isDiagCostChecked(diag_cost) ? (
                        <CheckBoxBaseIcon />
                      ) : (
                        <CheckBoxCheckedIcon />
                      )}
                    </div>
                    <p className="ml-3 text-left">{diag_cost.label}</p>
                  </button>
                );
              })}
            </div>
          )}
          {(siteHover || siteEnterHover) && (
            <div
              className="absolute left-80 flex flex-col bg-white shadow rounded-md w-80 mx-2 max-h-110 overflow-y-auto top-11"
              onMouseEnter={handleMouseEnterSite}
              onMouseLeave={handleMouseLeaveSite}
              style={{ top: getTopPixels(FILTERS.SITE)}}
            >
              <button
                className="flex my-2.5 items-center font-bold"
                onClick={siteBulkActionClickHandler}
              >
                <div className="ml-2 pointer-events-none">
                  {siteBulkActionIcon}
                </div>
                <p className="ml-3">{loc("Sites", lang)}</p>
              </button>
              <hr className="mx-auto w-10/12"></hr>

              {filterableSites.map((site) => {
                return (
                  <button
                    className="flex my-2.5 items-center"
                    onClick={() => toggleSite(site)}
                    key={site.id}
                  >
                    <div className="ml-2 pointer-events-none">
                      {!isSiteChecked(site) ? (
                        <CheckBoxBaseIcon />
                      ) : (
                        <CheckBoxCheckedIcon />
                      )}
                    </div>
                    <p className="ml-3 text-left">{site.name}</p>
                  </button>
                );
              })}
            </div>
          )}
          {(teamHover || teamEnterHover) && (
            <div
              className="absolute left-80 flex flex-col bg-white shadow rounded-md w-80 mx-2 max-h-110 overflow-y-auto"
              onMouseEnter={handleMouseEnterTeam}
              onMouseLeave={handleMouseLeaveTeam}
              style={{ top: getTopPixels(FILTERS.TEAM)}}
            >
              <button
                className="flex my-2.5 items-center font-bold"
                onClick={teamBulkActionClickHandler}
              >
                <div className="ml-2 pointer-events-none">
                  { teamBulkActionIcon }
                </div>
                <p className="ml-3">{loc("Équipes", lang)}</p>
              </button>
              <hr className="mx-auto w-10/12"></hr>
              {filterableTeams.map((team) => {
                return (
                  <button
                    className="flex my-2.5 items-center"
                    onClick={() => toggleTeam(team)}
                    key={team.id}
                  >
                    <div className="ml-2 pointer-events-none">
                      {!isTeamChecked(team) ? (
                        <CheckBoxBaseIcon />
                      ) : (
                        <CheckBoxCheckedIcon />
                      )}
                    </div>
                    <p className="ml-3 text-left">{team.name}</p>
                  </button>
                );
              })}
            </div>
          )}
          {(countryHover || countryEnterHover) && (
            <div
              className="absolute left-80 flex flex-col bg-white shadow rounded-md w-80 mx-2 max-h-110 overflow-y-auto"
              onMouseEnter={handleMouseEnterCountry}
              onMouseLeave={handleMouseLeaveCountry}
              style={{ top: getTopPixels(FILTERS.COUNTRY)}}
            >
              <button
                className="flex my-2.5 items-center font-bold"
                onClick={countryBulkActionClickHandler}
              >
                <div className="ml-2 pointer-events-none">
                  {countryBulkActionIcon}
                </div>
                <p className="ml-3">{loc("Pays", lang)}</p>
              </button>
              <hr className="mx-auto w-10/12"></hr>
              {filterableCountries.map((country) => {
                return (
                  <button
                    className="flex my-2.5 items-center"
                    onClick={() => toggleCountry(country)}
                    key={country.id}
                  >
                    <div className="ml-2 pointer-events-none">
                      {!isCountryChecked(country) ? (
                        <CheckBoxBaseIcon />
                      ) : (
                        <CheckBoxCheckedIcon />
                      )}
                    </div>
                    <p className="ml-3 text-left">{
                      `${country.emoji || ""} ${country.name}`
                    }</p>
                  </button>
                );
              })}
            </div>
          )}
          {(roleHover || roleEnterHover) && (
            <div
              className="absolute left-80 flex flex-col bg-white shadow rounded-md w-80 mx-2 max-h-110 overflow-y-auto"
              onMouseEnter={handleMouseEnterRole}
              onMouseLeave={handleMouseLeaveRole}
              style={{ top: getTopPixels(FILTERS.ROLE)}}
            >
              <button
                className="flex my-2.5 items-center font-bold"
                onClick={roleBulkActionClickHandler}
              >
                <div className="ml-2 pointer-events-none">
                  {roleBulkActionIcon}
                </div>
                <p className="ml-3">{loc("Rôles", lang)}</p>
              </button>
              <hr className="mx-auto w-10/12"></hr>
              {filterableRoles.map((role) => {
                return (
                  <button
                    className="flex my-2.5 items-center"
                    onClick={() => toggleRole(role)}
                    key={role.id}
                  >
                    <div className="ml-2 pointer-events-none">
                      {!isRoleChecked(role) ? (
                        <CheckBoxBaseIcon />
                      ) : (
                        <CheckBoxCheckedIcon />
                      )}
                    </div>
                    <p className="ml-3 text-left">{role.name}</p>
                  </button>
                );
              })}
            </div>
          )}
          {(delegatorHover || delegatorEnterHover) && (
            <div
              className="absolute left-80 flex flex-col bg-white shadow rounded-md w-80 mx-2 max-h-110 overflow-y-auto"
              onMouseEnter={handleMouseEnterDelegator}
              onMouseLeave={handleMouseLeaveDelegator}
              style={{ top: getTopPixels(FILTERS.DELEGATOR)}}
            >
              <button
                className="flex my-2.5 items-center font-bold"
                onClick={delegatorBulkActionClickHandler}
              >
                <div className="ml-2 pointer-events-none">
                  {delegatorBulkActionIcon}
                </div>
                <p className="ml-3">{loc("Clients", lang)}</p>
              </button>
              <hr className="mx-auto w-10/12"></hr>
              {filterableDelegators.map((delegator) => {
                return (
                  <button
                    className="flex my-2.5 items-center"
                    onClick={() => toggleDelegator(delegator)}
                    key={delegator.id}
                  >
                    <div className="ml-2 pointer-events-none">
                      {!isDelegatorChecked(delegator) ? (
                        <CheckBoxBaseIcon />
                      ) : (
                        <CheckBoxCheckedIcon />
                      )}
                    </div>
                    <p className="ml-3 text-left">{delegator.name}</p>
                  </button>
                );
              })}
            </div>
          )}
          {(delegationHover || delegationEnterHover) && (
            <div
              className="absolute left-80 flex flex-col bg-white shadow rounded-md w-80 mx-2 max-h-110 overflow-y-auto"
              onMouseEnter={handleMouseEnterDelegation}
              onMouseLeave={handleMouseLeaveDelegation}
              style={{ top: getTopPixels(FILTERS.DELEGATION)}}
            >
              <button
                className="flex my-2.5 items-center font-bold"
                onClick={delegationBulkActionClickHandler}
              >
                <div className="ml-2 pointer-events-none">
                  {delegationBulkActionIcon}
                </div>
                <p className="ml-3">{loc("Sociétés d'inspection", lang)}</p>
              </button>
              <hr className="mx-auto w-10/12"></hr>
              {filterableDelegations.map((delegation) => {
                return (
                  <button
                    className="flex my-2.5 items-center"
                    onClick={() => toggleDelegation(delegation)}
                    key={delegation.id}
                  >
                    <div className="ml-2 pointer-events-none">
                      {!isDelegationChecked(delegation) ? (
                        <CheckBoxBaseIcon />
                      ) : (
                        <CheckBoxCheckedIcon />
                      )}
                    </div>
                    <p className="ml-3 text-left">{delegation.name}</p>
                  </button>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

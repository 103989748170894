import { useMemo } from "react";
import { loc, getLocalString } from "../../helpers";
import { DecreaseIcon, IncreaseIcon } from "./Icons";
import Tooltip from "../components/Tooltip";

export function DataChartBox({ value, value_diff, comp_type, reverse, type, lang, label }) {
  const floatValue = useMemo(() => Math.round(parseFloat(value) * 100) / 100, [value]);
  const floatDiff = useMemo(() => Math.round(parseFloat(value_diff) * 100) / 100, [value_diff]);

  const compTypeLabel = useMemo(() => {
    if (comp_type === "yoy") {
      return "YoY"
    } else {
      return ""
    }
  }, [comp_type]);

  const compFormat = useMemo(() => {
    const format = {};
    if ((reverse && floatDiff > 0) || (!reverse && floatDiff < 0)) {
      format.bgColor = "bg-red-light";
      format.textColor = "text-red-dark";
    } else {
      format.bgColor = "bg-green-light";
      format.textColor = "text-green";
    }

    if (floatDiff < 0) {
      format.icon = <DecreaseIcon />;
    } else {
      format.icon = <IncreaseIcon />;
    }

    return format;
  }, [reverse, floatDiff]);


  const tooltipMessage = useMemo(() => {
    return loc("Comparaison avec la même période de l'année précédente", lang);
  }, [lang]);

  const shouldDisplayComp = useMemo(() => {
    return value_diff !== null && floatDiff !== Infinity;
  }, [value_diff, floatDiff]);

  return (
    <div className="bg-white mx-1 rounded-md p-4 border h-36 font-aeonik flex flex-col shadow-sm w-56 2xl:w-60 whitespace-nowrap">
      <p className="text-grey-light text-sm mb-auto">{ label }</p>
      <div className="flex items-baseline justify-between">
        <p className="text-black text-3xl 2xl:text-4xl">
          {(value
            ? getLocalString(floatValue, lang) + (type === "SOH" ? "%" : "")
            : "-")}
        </p>
        { shouldDisplayComp && (
          <Tooltip
            message={tooltipMessage}
            >
              <div className={`items-center flex ml-auto rounded-md ${compFormat.bgColor} ${compFormat.textColor} p-1 2xl:p-2 rounded-full text-xs 2xl:text-sm1 px-2`}>
                <div>
                  { compFormat.icon }
                </div>
                <p>
                  {" "}
                  {getLocalString(Math.abs(floatDiff, lang))}%{" " + compTypeLabel}
                </p>
              </div>
            </Tooltip>
          ) }
      </div>
    </div>
  );
}

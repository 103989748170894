import { Component } from "react";
import PieChart from "./PieChart";
import LineChart from "./LineChart";
import { convertMiles, loc, getLocalString } from "../../helpers";
import { pathImgCar } from "../../helpers";
import { FilterMoba } from "./FilterMoba";
import { DatePicker } from "./DatePicker";
import FilteredView from "./FilteredView";
import LoadingSpinner from "../components/LoadingSpinner";

import mixpanel from "mixpanel-browser";


function arraysAreEqual(array1, array2) {
  if ( array1 === array2 ) {
    return true;
  }
	if (array1?.length !== array2?.length) {
		return false;
	}

	for (let i = 0; i < array1.length; i++) {
		if (array1[i] !== array2[i]) {
			return false;
		}
	}

	return true;
}

class AnalyticsBoxes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			moba: props.moba,
			certificates: null,
			car_date_start: 1640991600,
			car_data_end: 1672524000,
      loading: true,

      typical_vehicle_type: null,

			pie_chart_percent_brand: null,
			pie_chart_percent_model: null,
			pie_chart_percent_odometer: null,
			pie_chart_percent_age: null,
			pie_chart_percent_soh: null,
			certificates_by_month: null,
			degration_by_age: null,
			site_chosen: "All",
			open: false,
			siteEnterHover: false,
			diagnosticHover: false,
			diagnosticEnterHover: false,
			selected: "soh",
			percent_pie: null,
			sites_checked: null,
      countries_checked: null,
		};

    this.getTypicalVehicleName = () => {
      const vehicleName = this.state.typical_vehicle_type?.name;
      return vehicleName ? vehicleName : null;
    };

    this.getTypicalVehicleOdometer = () => {
      let odometer = this.state.typical_vehicle_type?.odometer?.avg;
      const unit = this.state.moba.unit;
      if (unit === "miles") {
        odometer = convertMiles(odometer);
      }
      if (odometer) {
        return getLocalString(Math.round(odometer), this.props.lang) + " " + unit;
      } else {
        return null;
      }
    };

    this.getTypicalVehicleAge = () => {
      const age = this.state.typical_vehicle_type?.age?.avg;
      if (age) {
        return Math.round(age) + " mois";
      } else {
        return null;
      }
    };

    this.getTypicalVehicleSOH = () => {
      const soh = this.state.typical_vehicle_type?.soh?.avg;
      if (soh) {
        return Math.round(soh * 10) / 10 + "%";
      } else {
        return null;
      }
    };

    this.shouldPrintPie = () => {
        const shouldPrint = this.state.percent_pie &&
            (this.state.percent_pie?.length && Math.max(...this.state.percent_pie) > 0 || Math.max(...Object.values(this.state.percent_pie)) > 0)
        return shouldPrint
    }
        
		this.onChange = dates => {
			if (!dates || dates.length < 2) {
				this.props.setState({
					startDate: new Date(
						new Date().setFullYear(new Date().getFullYear() - 5)
					),
					endDate: new Date(),
				});
			} else {
				const [start, end] = dates;
				this.props.setState({
					startDate: start,
					endDate: end,
				});
			}
		};

		this.setSitesFiltered = (sites_checked) => {
			if(sites_checked) {
				this.setState({
					sites_checked: sites_checked
				})	
			}
			else {
				this.setState({
					sites_checked: null
				})
			}
		}

    this.setCountriesFiltered = (countries_checked) => {
      if(countries_checked) {
        this.setState({
          countries_checked: countries_checked
        })
      }
      else {
        this.setState({
          countries_checked: null
        })
      }
    }

    this.setRightPercentPie = () => {
        const type = this.state.selected;

        let percentPie = null;
        if (type === "model") {
            percentPie = this.state.pie_chart_percent_model;
        } else if (type === "soh") {
            percentPie = this.state.pie_chart_percent_soh;
        } else if (type === "age") {
            percentPie = this.state.pie_chart_percent_age;
        } else if (type === "odometer") {
            percentPie = this.state.pie_chart_percent_odometer;
        }
        this.setState({
            percent_pie: percentPie
        });
    };

		this.updateStateBoxes = async () => {
      this.setState({ loading: true }, () => {
        setTimeout(() => {
          this.state.moba.loadAllAnalyticsData();
          this.setState({
            certificates: this.state.moba.certificates,
            typical_vehicle_type: this.state.moba.typical_vehicle_type,
            pie_chart_percent_brand:
              this.state.moba.pie_chart_percent_brand,
            pie_chart_percent_model:
              this.state.moba.pie_chart_percent_model,
            pie_chart_percent_odometer:
              this.state.moba.pie_chart_percent_odometer,
            pie_chart_percent_age: this.state.moba.pie_chart_percent_age,
            pie_chart_percent_soh: this.state.moba.pie_chart_percent_soh,
            certificates_by_month: this.state.moba.certificates_by_month,
            degration_by_age: this.state.moba.degration_by_age,
          }, () => {
            this.setRightPercentPie();
            this.setState({ loading: false });
          });
        }, 0);
      });
		};
	}

	componentDidMount(prevProps, prevState) {
    mixpanel.track("User Opened Page", { page: "Analytics", subpage: "Charts" });
    setTimeout(() => {
		  this.updateStateBoxes();
    }, 0);
	}

	componentDidUpdate(prevProps, prevState) {
    const shouldUpdateBoxes = (
      this.props.startDate !== prevProps.startDate
      || this.props.endDate !== prevProps.endDate
      || !arraysAreEqual(prevState.sites_checked, this.state.sites_checked)
      || !arraysAreEqual(prevState.countries_checked, this.state.countries_checked)
    );

		if ( shouldUpdateBoxes ) {
			this.state.moba.filterCertificates(
        {
          date: [
            this.props.startDate,
            this.props.endDate,
          ],
          site: this.state.sites_checked,
          country: this.state.countries_checked
        }
			);
      setTimeout(() => {
        this.updateStateBoxes();
      }, 0);
		}
		if (prevState.show !== this.props.show) {
			if (this.props.show === true && this.props.open === true) {
				this.props.setState({ open: false });
			}
		}
		if (prevState.open !== this.props.open) {
			if (this.props.open === true && this.props.show === true) {
				this.props.setState({ show: false });
			}
		}
	}
	componentWillUnmount() {
		this.state.moba.filterCertificates();
	}

  pieBox() {
    let boxBody;
    if (this.state.loading) {
      boxBody = <LoadingSpinner />;
    } else if (!this.shouldPrintPie()) {
      boxBody = <div className="h-full flex flex-1 justify-center items-center">{loc("Aucune donnée disponible", this.props.lang)}</div>;
    } else {
      boxBody = (
        <div className="flex flex-col h-full">
          <div className="flex justify-center w-full relative right-0 -z-50 h-100">
            <PieChart
                percent_pie={
                  this.state
                    .percent_pie
                }
                type={this.state.selected}
                lang={
                  this.props.lang
                }

                showLegend={true}
              ></PieChart>
          </div>
        </div>
      )
    }

    return (
      <div className="bg-white shadow-sm rounded-xl border border-grey-200 h-80 w-130 2xl:w-11/12 mr-auto ml-2 flex flex-col">
        <div className="flex justify-center border-b border-grey-200 z-10 h-10">
          <button
            onClick={
              () => {
                this.setState({
                  selected: "model",
                  percent_pie: this.state
                    .pie_chart_percent_model
                });
              }
            }
            className={
              "flex items-center m-auto " +
              (this.state.selected ===
                "model"
                ? "text-branding-500"
                : "text-grey-500")
            }
          >
            {loc(
              "Modèles",
              this.props.lang
            )}
          </button>
          <button
            onClick={
              () => {
                this.setState({
                  selected: "soh",
                  percent_pie: this.state
                    .pie_chart_percent_soh
                });
              }
            }
            className={
              "flex items-center m-auto " +
              (this.state.selected ===
                "soh"
                ? "text-branding-500"
                : "text-grey-500")
            }
          >
            {loc(
              "State of Health",
              this.props.lang
            )}
          </button>
          <button
            onClick={
              () => {
                this.setState({
                  selected: "age",
                  percent_pie: this.state
                    .pie_chart_percent_age
                });
              }
            }
            className={
              "flex items-center m-auto " +
              (this.state.selected ===
                "age"
                ? "text-branding-500"
                : "text-grey-500")
            }
          >
            {loc(
              "Âge",
              this.props.lang
            )}
          </button>
          <button
            onClick={
              () => {
                this.setState({
                  selected: "odometer",
                  percent_pie: this.state
                    .pie_chart_percent_odometer
                });
              }
            }
            className={
              "flex items-center m-auto " +
              (this.state.selected ===
                "odometer"
                ? "text-branding-500"
                : "text-grey-500")
            }
          >
            {loc(
              "Kilométrage",
              this.props.lang
            )}
          </button>
        </div>
        { boxBody }
      </div>
    )
  }

  typicalVehicleBox() {
    const typicalVehicleName = this.getTypicalVehicleName();
    const typicalVehicleOdometer = this.getTypicalVehicleOdometer();
    const typicalVehicleAge = this.getTypicalVehicleAge();
    const typicalVehicleSOH = this.getTypicalVehicleSOH();

    let boxBody;

    if (this.state.loading) {
      boxBody = <LoadingSpinner />;
    } else if (!typicalVehicleName || !typicalVehicleSOH) {
      boxBody = <div className="h-full flex flex-1 justify-center items-center">{loc("Aucune donnée disponible", this.props.lang)}</div>;
    } else {
      boxBody = (
        <div className="flex flex-row mt-10">
          <img
            className="mr-auto mt-auto"
            src={pathImgCar(typicalVehicleName)}
            width="300"
            height="200"
            alt="car"
          />
          <div className="m-auto w-1/2 text-aeonik">
            <p className="text-grey-500 text-aeonik ml-1">{ typicalVehicleName }</p>
            { typicalVehicleOdometer && <p className="text-grey-500 text-aeonik ml-1">{ typicalVehicleOdometer }</p> }
            { typicalVehicleAge && <p className="text-grey-500 text-aeonik ml-1">{ typicalVehicleAge }</p> }
            { typicalVehicleSOH && <p className="text-grey-500 text-aeonik ml-1">{ typicalVehicleSOH }</p> }
          </div>
        </div>
      );
    }

    return (
      <div className="bg-white shadow-sm rounded-xl border border-grey-200 h-80 w-130 2xl:w-11/12 ml-auto mr-2 flex flex-col">
        <div className="flex">
          <p className="text-aeonik text-grey-800 text-lg mt-3 ml-5">
            {loc(
              "Voiture type",
              this.props.lang
            )}
          </p>
        </div>
        { boxBody }
      </div>
    );
  }

  diagsByTeamBox() {
    let boxBody;
    if (this.state.loading) {
      boxBody = <LoadingSpinner />;
    } else if (!this.state.certificates_by_month || Math.max(...Object.values(this.state.certificates_by_month).flat()) === 0) {
      boxBody = <div className="h-full flex flex-1 justify-center items-center">{loc("Aucune donnée disponible", this.props.lang)}</div>;
    } else {
      boxBody = (
        <LineChart
          data={
            this.state
              .certificates_by_month
          }
          type={"diags"}
          lang={this.props.lang}
        ></LineChart>
      );
    }
    return (
      <div className="bg-white shadow-sm rounded-xl border border-grey-200 h-80 w-130 2xl:w-11/12 ml-auto mr-2 flex flex-col">
        <p className="text-aeonik text-grey-800 text-lg my-3 ml-5">
          {loc(
            "Diagnostics par équipe",
            this.props.lang
          )}
        </p>
        { boxBody }
      </div>
    );
  }


	render() {
		return (
				<div className="mt-2 h-full">
					<div className="flex-col">
						<div className="flex">
							<FilterMoba
								filterOpened={false}
								setFilterOpened={() => null}
								column={{moba: this.props.moba, filters: ["SITE", "COUNTRY"]}}
								setType={() => null}
								sitesFiltered={this.state.sites_checked}
								setSitesFiltered={this.setSitesFiltered}
								countriesFiltered={this.state.countries_checked}
								setCountriesFiltered={this.setCountriesFiltered}
							></FilterMoba>
							<DatePicker
								opacity={this.props.opacity}
								days={this.props.days}
								setDays={this.props.setDays}
								show={this.props.show}
								setShow={this.props.setShow}
								startDate={this.props.startDate}
								endDate={this.props.endDate}
								toPrint={this.props.toPrint}
								setToPrint={this.props.setToPrint}
								onChange={this.onChange}
                lang={this.props.lang}
							/>
						</div>
						<div className="mt-4 border-0.5 border-grey-200"></div>

						<div className="flex">
              <FilteredView lang={this.props.lang} itemsFiltered={this.state.sites_checked} setItemsFiltered={this.setSitesFiltered} filterType="SITES" />
              <FilteredView lang={this.props.lang} itemsFiltered={this.state.countries_checked} setItemsFiltered={this.setCountriesFiltered} filterType="COUNTRIES" />
						</div>
						<div className="flex my-4">
							<div className="w-full grid grid-cols-2 gap-y-7">
								{ this.diagsByTeamBox() }
								{ this.pieBox() }
								{ this.typicalVehicleBox() }
							</div>
						</div>
					</div>
				</div >
			);
	}
}

export default AnalyticsBoxes;
